import CreditScoreIcon from "@mui/icons-material/CreditScore"
import SubscriptionsIcon from "@mui/icons-material/Subscriptions"
import PaymentsIcon from "@mui/icons-material/Payments"
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"

export const businessRoutes = [
  {
    name: "Overview",
    icon: <SubscriptionsIcon />,
    route: "/overview",
  },
  {
    name: "Billing",
    icon: <PaymentsIcon />,
    route: "/billing",
  },
  {
    name: "Subscriptions",
    icon: <AccountBalanceWalletIcon />,
    route: "/subscriptions",
  },
  {
    name: "Management",
    icon: <CreditScoreIcon />,
    route: "/management",
  },
]
