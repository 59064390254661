import React, { Fragment, forwardRef, useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import { Button, Divider } from "@mui/material"
import GridItem from "../../basic/grid/GridItem"
import { Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import GridContainer from "../../basic/grid/GridContainer"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableCellBorder = styled(TableCell)(({ theme }) => ({
  // borderRight: `2px solid ${theme.palette.common.black}`,
  // [`&.${tableCellClasses.head}`]: {
  //   backgroundColor: theme.palette.common.black,
  //   color: theme.palette.common.white,
  // },
}))

const styles = {
  submitButton: {
    mt: "25px",
    mb: "15px",
    ml: "auto",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "16px",
    width: "100px",
    borderRadius: "20px",
    display: "flex",
  },
  button: {
    textTransform: "none",
    fontSize: "15px",
    borderRadius: "20px",
    fontWeight: "bold",
    color: "white",
    border: `1px solid white`,
    "&:hover": {
      color: "#f2f3f5",
      border: `1px solid white`,
      backgroundColor: "transparent",
    },
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper21: {
    mt: "20px",
    flex: 1,
    height: "auto",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    padding: "20px",
    minWidth: "480px",
  },
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SubscriptionPlanModal = () => {
  const [openPlanDialog, setOpenPlanDialog] = useState(false)

  return (
    <Fragment>
      <Button onClick={() => setOpenPlanDialog(true)} sx={styles.button} variant="outlined">
        See pricing details
      </Button>
      <Dialog
        maxWidth="lg"
        open={openPlanDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenPlanDialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="body2" sx={{ fontSize: "16px", mt: "15px" }}>
            Subscription cost values valid for $/€/CHF | Pay-per-use model | 16.04.2024
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <GridContainer sx={styles.grid}>
            {/* <GridContainer>
              <Typography variant="body2" sx={{ fontSize: "16px", mt: "15px" }}>
                Subscription cost values valid for $/€/CHF | Pay-per-use model | 16.04.2024
              </Typography>
            </GridContainer> */}
            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
              <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="right">Trial</StyledTableCell>
                    <StyledTableCell align="right">Starter</StyledTableCell>
                    <StyledTableCell align="right">Premium</StyledTableCell>
                    <StyledTableCell align="right">Enterprise</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={"Monthly base fee - annual term (invoiced monthly)"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row" rowSpan={2}>
                      Base fee
                    </TableCell>
                    <StyledTableCellBorder>Monthly base fee - annual term (invoiced monthly)</StyledTableCellBorder>
                    <TableCell align="right">0</TableCell>
                    <TableCell align="right">199</TableCell>
                    <TableCell align="right">499</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Monthly base fee - monthly term (invoiced monthly)"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Monthly base fee - monthly term (invoiced monthly)</StyledTableCellBorder>
                    <TableCell align="right">0</TableCell>
                    <TableCell align="right">249</TableCell>
                    <TableCell align="right">599</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>

                  <TableRow key={"Cloud type"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Cloud
                    </TableCell>
                    <StyledTableCellBorder>Cloud type</StyledTableCellBorder>
                    <TableCell align="right">Private</TableCell>
                    <TableCell align="right">Private</TableCell>
                    <TableCell align="right">Private</TableCell>
                    <StyledTableCellBorder align="right">Private, On-Prem</StyledTableCellBorder>
                  </TableRow>

                  <TableRow key={"Data Read Operation of size 1 KB"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row" rowSpan={9}>
                      Data
                    </TableCell>
                    <StyledTableCellBorder>Data Read Operation of size 1 KB</StyledTableCellBorder>
                    <TableCell align="right">0</TableCell>
                    <TableCell align="right">0.000001</TableCell>
                    <TableCell align="right">0.000001</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Data Write Operation of size 1 KB"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Data Write Operation of size 1 KB</StyledTableCellBorder>
                    <TableCell align="right">0</TableCell>
                    <TableCell align="right">0.000002</TableCell>
                    <TableCell align="right">0.000002</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Data Storage Persistent per day (1 KB)"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Data Storage Persistent per day (1 KB)</StyledTableCellBorder>
                    <TableCell align="right">0</TableCell>
                    <TableCell align="right">0.0000001</TableCell>
                    <TableCell align="right">0.0000001</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Data Read Operation (1 Mio.of size 1 KB)"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Data Read Operation (1 Mio. of size 1 KB)</StyledTableCellBorder>
                    <TableCell align="right">0</TableCell>
                    <TableCell align="right">1</TableCell>
                    <TableCell align="right">1</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Data Write Operation (1 Mio.of size 1 KB)"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Data Write Operation (1 Mio.of size 1 KB)</StyledTableCellBorder>
                    <TableCell align="right">0</TableCell>
                    <TableCell align="right">2</TableCell>
                    <TableCell align="right">2</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Data Storage Persistent per month (1 GB)"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Data Storage Persistent per month (1 GB)</StyledTableCellBorder>
                    <TableCell align="right">0</TableCell>
                    <TableCell align="right">3</TableCell>
                    <TableCell align="right">3</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Data storage volume pricing"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Data storage volume pricing</StyledTableCellBorder>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">yes</TableCell>
                    <StyledTableCellBorder align="right">yes</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Data Operation request rate per second"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Data Operation request rate per second</StyledTableCellBorder>
                    <TableCell align="right">5</TableCell>
                    <TableCell align="right">20</TableCell>
                    <TableCell align="right">50</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                  <TableRow
                    key={"Continuous Data Aggregation Operation (1 Mio. of size 1 KB)"}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCellBorder>Continuous Data Aggregation Operation (1 Mio. of size 1 KB)</StyledTableCellBorder>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">4</TableCell>
                    <TableCell align="right">4</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>

                  <TableRow key={"Data retention (in month)"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Retention
                    </TableCell>
                    <StyledTableCellBorder>Data retention (in month)</StyledTableCellBorder>
                    <TableCell align="right">1</TableCell>
                    <TableCell align="right">unlimited</TableCell>
                    <TableCell align="right">unlimited</TableCell>
                    <StyledTableCellBorder align="right">unlimited</StyledTableCellBorder>
                  </TableRow>

                  <TableRow key={"Visualization App Generic-Client (web, mobile)"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row" rowSpan={5}>
                      Visualization
                    </TableCell>
                    <StyledTableCellBorder>Visualization App Generic-Client (web, mobile)</StyledTableCellBorder>
                    <TableCell align="right">yes</TableCell>
                    <TableCell align="right">yes</TableCell>
                    <TableCell align="right">yes</TableCell>
                    <StyledTableCellBorder align="right">yes</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Dashboards Generic-Client"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Dashboards Generic-Client</StyledTableCellBorder>
                    <TableCell align="right">10</TableCell>
                    <TableCell align="right">50</TableCell>
                    <TableCell align="right">100</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Dashboard Builder Generic-Client"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Dashboard Builder Generic-Client</StyledTableCellBorder>
                    <TableCell align="right">yes</TableCell>
                    <TableCell align="right">yes</TableCell>
                    <TableCell align="right">yes</TableCell>
                    <StyledTableCellBorder align="right">yes</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Widget-Customization Generic-Client"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Widget-Customization Generic-Client</StyledTableCellBorder>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">yes</TableCell>
                    <StyledTableCellBorder align="right">yes</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Customized Visualization Apps (web, mobile)"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Customized Visualization Apps (web, mobile)</StyledTableCellBorder>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">-</TableCell>
                    <StyledTableCellBorder align="right">yes</StyledTableCellBorder>
                  </TableRow>

                  <TableRow key={"User management"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row" rowSpan={3}>
                      User
                    </TableCell>
                    <StyledTableCellBorder>User management</StyledTableCellBorder>
                    <TableCell align="right">yes</TableCell>
                    <TableCell align="right">yes</TableCell>
                    <TableCell align="right">yes</TableCell>
                    <StyledTableCellBorder align="right">yes</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Users"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Users</StyledTableCellBorder>
                    <TableCell align="right">5</TableCell>
                    <TableCell align="right">50</TableCell>
                    <TableCell align="right">200</TableCell>
                    <StyledTableCellBorder align="right">unlimited</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"Multi-Tenancy"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>Multi-Tenancy</StyledTableCellBorder>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">-</TableCell>
                    <StyledTableCellBorder align="right">yes</StyledTableCellBorder>
                  </TableRow>

                  <TableRow key={"Time limit (in days)"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Validity
                    </TableCell>
                    <StyledTableCellBorder>Time limit (in days)</StyledTableCellBorder>
                    <TableCell align="right">30</TableCell>
                    <TableCell align="right">unlimited</TableCell>
                    <TableCell align="right">unlimited</TableCell>
                    <StyledTableCellBorder align="right">unlimited</StyledTableCellBorder>
                  </TableRow>

                  <TableRow key={"White labeling"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Customization
                    </TableCell>
                    <StyledTableCellBorder>White labeling</StyledTableCellBorder>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">-</TableCell>
                    <StyledTableCellBorder align="right">yes</StyledTableCellBorder>
                  </TableRow>

                  <TableRow key={"Support"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row" rowSpan={2}>
                      Services
                    </TableCell>
                    <StyledTableCellBorder>Support</StyledTableCellBorder>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">5x8h</TableCell>
                    <TableCell align="right">7x8h</TableCell>
                    <StyledTableCellBorder align="right">24/7</StyledTableCellBorder>
                  </TableRow>
                  <TableRow key={"IoT solution design call (one-time)"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCellBorder>IoT solution design call (one-time)</StyledTableCellBorder>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">30 min.</TableCell>
                    <TableCell align="right">60 min.</TableCell>
                    <StyledTableCellBorder align="right">Prof. services</StyledTableCellBorder>
                  </TableRow>

                  <TableRow
                    key={"Data Storage Persistent per month (1 GB, total > 500 GB)"}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, backgroundColor: "#cccccc" }}
                  >
                    <TableCell component="th" scope="row" rowSpan={3}>
                      Data Storage Persistant volume pricing
                    </TableCell>
                    <StyledTableCellBorder>{"Data Storage Persistent per month (1 GB, total > 500 GB)"}</StyledTableCellBorder>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">2</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                  <TableRow
                    key={"Data Storage Persistent per month (1 GB, total > 1 TB (= 1.000 GB))"}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, backgroundColor: "#cccccc" }}
                  >
                    <StyledTableCellBorder>{"Data Storage Persistent per month (1 GB, total > 1 TB (= 1.000 GB))"}</StyledTableCellBorder>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">1</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                  <TableRow
                    key={"Data Storage Persistent per month (1 GB, total > 500 TB"}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, backgroundColor: "#cccccc" }}
                  >
                    <StyledTableCellBorder>{"Data Storage Persistent per month (1 GB, total > 500 TB)"}</StyledTableCellBorder>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">0.5</TableCell>
                    <StyledTableCellBorder align="right">On request</StyledTableCellBorder>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <GridItem sx={{width: "100%"}}>
              <Paper sx={styles.paper21}>
                <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "bold", mb: "15px", ml: "15px" }}>
                  Hint:
                </Typography>
                <GridContainer>
                  <Typography variant="body2" sx={{ fontSize: "14px", width: "300px", ml: "15px" }}>
                    Data Write Operation (DWO)
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "14px", pl: "15px" }}>
                    Written data volume, rounded up to next KB
                  </Typography>
                </GridContainer>
                <GridContainer>
                  <Typography variant="body2" sx={{ fontSize: "14px", width: "300px", ml: "15px" }}>
                    Data Read Operation (DRO)
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "14px", pl: "15px" }}>
                    Read data volume, rounded up to next KB
                  </Typography>
                </GridContainer>
                <GridContainer>
                  <Typography variant="body2" sx={{ fontSize: "14px", width: "300px", ml: "15px" }}>
                    Data Storage Persistent (DSP)
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "14px", pl: "15px" }}>
                    Stored data volume, rounded up to next KB
                  </Typography>
                </GridContainer>
              </Paper>
              <Button variant="outlined" onClick={() => setOpenPlanDialog(false)} sx={styles.submitButton}>
                Close
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default SubscriptionPlanModal
