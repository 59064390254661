import React from "react"
import GridContainer from "../../basic/grid/GridContainer"
import GridItem from "../../basic/grid/GridItem"
import { Divider, Paper, Typography } from "@mui/material"
import UsageGraph from "../charts/UsageGraph"
import { Subscription } from "../../../repository/SubscriptionsRepository"
import { AppTheme } from "../../../AppTheme"

interface Subscriptions {
  subscription: Subscription
  graphData: any
}

const styles = {
  cards: {
    minWidth: "500px",
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  expandIcon: {
    color: "black",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: "150px",
    height: "100px",
    background: `linear-gradient(45deg, ${AppTheme.palette.primary.light} ,white, ${AppTheme.palette.primary.light} )`,
    borderRadius: "20px",
    boxShadow: 5,
  },
  paper2: {
    mt: "20px",
    flex: 1.5,
    height: "400px",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    padding: "20px",
    minWidth: "400px",
  },
  paper3: {
    mt: "20px",
    flex: 1,
    height: "auto",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    paddingX: "40px",
    paddingY: "20px",
    minWidth: "400px",
  },
  paper4: {
    mt: "20px",
    flex: 1,
    height: "auto",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    padding: "20px",
    minWidth: "400px",
  },
  divider: {
    flex: 1,
    my: "auto",
    minWidth: "200px",
    height: "10px",
    background: AppTheme.palette.primary.light,
  },
}

const OverviewTabPage = ({ graphData, subscription }: Subscriptions) => {
  const monthAbbreviations = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
  const ampm = new Date(parseInt(subscription?.creationDate)).getHours() >= 12 ? 'PM' : 'AM'

  const monthAbbrevStart = monthAbbreviations[new Date(parseInt(subscription?.startDate)).getMonth()]
  const monthAbbrevEnd = monthAbbreviations[new Date(parseInt(subscription?.endDate)).getMonth()]

  const startDate = new Date(parseInt(subscription?.startDate)).getDate() + " " + monthAbbrevStart + " " + new Date(parseInt(subscription?.startDate)).getFullYear()
  const endDate = new Date(parseInt(subscription?.endDate)).getDate() + " " + monthAbbrevEnd + " " + new Date(parseInt(subscription?.endDate)).getFullYear()
  const creationDateTime = new Date(parseInt(subscription?.creationDate)).getDate() + " " + monthAbbrevEnd + " " + new Date(parseInt(subscription?.creationDate)).getFullYear() + " " + new Date(parseInt(subscription?.creationDate)).getHours() + ":" + new Date(parseInt(subscription?.creationDate)).getMinutes() + " " + ampm

  const startDate1 =
    new Date(parseInt(subscription?.startDate)).getDate() +
    "/" +
    (new Date(parseInt(subscription?.startDate)).getMonth() + 1) +
    "/" +
    new Date(parseInt(subscription?.startDate)).getFullYear()
  const endDate1 =
    new Date(parseInt(subscription?.endDate)).getDate() +
    "/" +
    (new Date(parseInt(subscription?.endDate)).getMonth() + 1) +
    "/" +
    new Date(parseInt(subscription?.endDate)).getFullYear()

  return (
    <>
      <GridContainer sx={styles.cards}>
        <Paper sx={styles.paper}>
          <GridItem sx={{ height: "100px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "bold" }}>
              Start date
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "bold" }}>
              {startDate}
            </Typography>
          </GridItem>
        </Paper>
        <Divider sx={styles.divider} />
        <Paper sx={styles.paper}>
          <GridItem sx={{ height: "100px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "bold" }}>
              End date
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "bold" }}>
              {endDate}
            </Typography>
          </GridItem>
        </Paper>
      </GridContainer>
      <Divider sx={{ width: "100%", mt: "25px" }} />
      <GridContainer sx={{ flex: 1, mt: "25px", gap: "20px" }}>
        <GridItem sx={{ flex: 1.5 }}>
          <Paper sx={styles.paper2}>
            <GridContainer>
              <GridItem sx={{ height: "350px", width: "100%" }}>
                <Typography variant="body2" sx={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "bold" }}>
                  Last 6 months usage history
                </Typography>
                {/* <UsageGraph rowData={graphData} /> */}
              </GridItem>
            </GridContainer>
          </Paper>
          <Paper sx={styles.paper2}>
            <GridItem>
              <GridContainer>
                <Typography variant="body2" sx={{ fontSize: "16px", width: "160px", textAlign: "right" }}>
                  Product name:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
                  {subscription?.serviceName}
                </Typography>
              </GridContainer>
              <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
              <GridContainer>
                <Typography variant="body2" sx={{ fontSize: "16px", width: "160px", textAlign: "right" }}>
                  Subscription status:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
                  Active
                </Typography>
              </GridContainer>
              <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
              <GridContainer>
                <Typography variant="body2" sx={{ fontSize: "16px", width: "160px", textAlign: "right" }}>
                  Subscription ID:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
                  564698
                </Typography>
              </GridContainer>
              <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
              <GridContainer>
                <Typography variant="body2" sx={{ fontSize: "16px", width: "160px", textAlign: "right" }}>
                  Plan name:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
                  Siot
                </Typography>
              </GridContainer>
              <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
              <GridContainer>
                <Typography variant="body2" sx={{ fontSize: "16px", width: "160px", textAlign: "right" }}>
                  Total licences:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
                  {subscription?.totalLicences}
                </Typography>
              </GridContainer>
              <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
              <GridContainer>
                <Typography variant="body2" sx={{ fontSize: "16px", width: "160px", textAlign: "right" }}>
                  Asigned:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
                  ssp
                </Typography>
              </GridContainer>
              <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
            </GridItem>
          </Paper>
        </GridItem>
        <GridItem sx={{ flex: 1 }}>
          <Paper sx={styles.paper3}>
            <GridContainer sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", mb: "30px" }}>
              <GridItem sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  Start date
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", color: AppTheme.palette.primary.main, fontWeight: "bold" }}>
                  {startDate1}
                </Typography>
              </GridItem>
              <GridItem sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  End date
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", color: AppTheme.palette.primary.main, fontWeight: "bold" }}>
                  {endDate1}
                </Typography>
              </GridItem>
            </GridContainer>
            <GridContainer sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", mb: "30px" }}>
              <GridItem sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  Billing Cycle
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", color: AppTheme.palette.primary.main, fontWeight: "bold" }}>
                  {subscription?.billingCycle}
                </Typography>
              </GridItem>
              <GridItem sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  Next Billing
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", color: AppTheme.palette.primary.main, fontWeight: "bold" }}>
                  12/12/2024
                </Typography>
              </GridItem>
            </GridContainer>
            <GridContainer sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <GridItem sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  Auto-Renew:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", color: AppTheme.palette.primary.main, fontWeight: "bold", ml: "5px" }}>
                  {subscription?.autoRenewal ? "ON" : "OFF"}
                </Typography>
              </GridItem>
              <GridItem sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  Notifications:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", color: AppTheme.palette.primary.main, fontWeight: "bold", ml: "5px" }}>
                  {subscription?.notifications ? "ON" : "OFF"}
                </Typography>
              </GridItem>
            </GridContainer>
          </Paper>
          <Paper sx={styles.paper4}>
            <Typography variant="body2" sx={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "bold" }}>
              Additional informations
            </Typography>
            <GridItem sx={{ mt: "20px" }}>
              <GridContainer>
                <Typography variant="body2" sx={{ fontSize: "16px", width: "160px", textAlign: "right" }}>
                  IP protocol:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
                  icmp
                </Typography>
              </GridContainer>
              <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
              <GridContainer>
                <Typography variant="body2" sx={{ fontSize: "16px", width: "160px", textAlign: "right" }}>
                  Creation time:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
                  {creationDateTime}
                </Typography>
              </GridContainer>
              <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
              <GridContainer>
                <Typography variant="body2" sx={{ fontSize: "16px", width: "160px", textAlign: "right" }}>
                  Description:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
                  Lorem ipsum lorem ipsum lorem
                </Typography>
              </GridContainer>
              <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
            </GridItem>
          </Paper>
          <Paper sx={styles.paper4}>
            <Typography variant="body2" sx={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "bold" }}>
              Audit
            </Typography>
            <GridItem sx={{ mt: "20px" }}>
              <GridContainer sx={{ justifyContent: "space-between" }}>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Action
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Performed by
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Creation time
                </Typography>
                <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
              </GridContainer>
              <GridContainer sx={{ justifyContent: "space-between" }}>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  New subscription
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  Admin
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  12 dec 2022
                </Typography>
                <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
              </GridContainer>
              <GridContainer sx={{ justifyContent: "space-between" }}>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  Resources added
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  Admin
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  15 mar 2023
                </Typography>
                <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
              </GridContainer>
            </GridItem>
          </Paper>
        </GridItem>
      </GridContainer>
    </>
  )
}
export default OverviewTabPage
