import React from "react"
import { useNavigate } from "react-router-dom"
import GridContainer from "../../components/basic/grid/GridContainer"
import GridItem from "../../components/basic/grid/GridItem"
import { Divider, Typography } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import ContainedButton from "../../components/basic/buttons/ContainedButton"
import { useState } from "react"
import { AppTheme } from "../../AppTheme"
import DependencyContainer from "../../DependencyContainer"
import { useQuery } from "@tanstack/react-query"
// import { Subscription } from "../../repository/SubscriptionsRepository"
import SuccessModal from "../../components/advanced/modals/SuccessModal"
import SubscriptionPlanModal from "../../components/advanced/modals/SubscriptionsPlanModal"

const styles = {
  container: {
    display: "flex",
    gap: "20px",
    mb: "20px",
  },
  outlineBox: {
    flex: 1,
    minWidth: 380,
    maxWidth: 550,
    backgroundColor: "#f2f2f2",
    boxShadow: 5,
    borderRadius: 4,
    height: "730px",
    position: "relative",
  },
  highlited: {
    transform: "translate(25px, -12px)",
    width: "fit-content",
    backgroundColor: "#16f738",
    color: "black",
    borderRadius: "5px",
    fontSize: "10px",
    fontWeight: "bold",
  },
  box: {
    backgroundColor: "#F2F4FA",
    borderRadius: "15px",
    "&:hover": {
      outline: "1px solid #babfc7",
    },
  },
  typography: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  typography1: {
    fontSize: "16px",
    mb: "15px",
  },
  heading: {
    px: "25px",
    pt: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    mt: "10px",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
  },
  description: {
    fontSize: "16px",
    mb: "20px",
  },
  price: {
    display: "flex",
    alignItems: "baseline",
    flexDirection: "row",
  },
  priceAmount: {
    fontSize: "25px",
    fontWeight: "bold",
  },
  pricePeriod: {
    fontSize: "18px",
    fontWeight: "bold",
    ml: "10px",
    color: "#888888",
  },
  list: {
    fontSize: "13px",
    mt: "20px",
    px: "15px",
    borderBottom: "5px solid white",
    height: "155px",
  },
  list1: {
    fontSize: "13px",
    mt: "20px",
    px: "15px",
    borderBottom: "5px solid white",
    height: "100px",
  },
  listTitle: {
    color: "#666666",
    mb: "10px",
    fontWeight: "bold",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    mb: "4px",
  },
  listIcon: {
    fontSize: "small",
    color: "#1e8aff",
    height: "22px",
  },
  listText: {
    ml: "8px",
  },
  buttonStack: {
    mx: "20px",
    position: "absolute",
    bottom: "20px",
    backgroundColor: "#f2f2f2",
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "15px",
    borderRadius: "20px",
    color: "white",
    backgroundColor: AppTheme.palette.primary.main,
    "&:hover": {
      color: "#f2f3f5",
      transition: ".4s ease",
      backgroundColor: AppTheme.palette.primary.main,
    },
  },
  button11: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "16px",
    borderRadius: "20px",
    color: "white",
    width: "150px",
    backgroundColor: "#4472c4",
    "&:hover": {
      color: "#f2f3f5",
      transition: ".4s ease",
      backgroundColor: "#4472c4",
    },
  },
  button1: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    borderRadius: "20px",
    color: AppTheme.palette.primary.dark,
    border: `1px solid ${AppTheme.palette.primary.dark}`,
    "&:hover": {
      color: AppTheme.palette.primary.main,
      border: `1px solid ${AppTheme.palette.primary.main}`,
    },
  },
  button2: {
    textTransform: "none",
    fontSize: "15px",
    borderRadius: "20px",
    fontWeight: "bold",
    color: "white",
    border: `1px solid white`,
    "&:hover": {
      color: "#f2f3f5",
      border: `1px solid white`,
      backgroundColor: "transparent"
    },
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  currencies: {
    my: { xs: "5px", sm: "5px", md: "35px", lg: "35px", xl: "35px" },
    borderRadius: "5px",
    p: "3px",
  },
  currencies1: {
    mb: "50px",
    borderRadius: "15px",
    p: "5px",
    border: "1px solid #dbdbdb",
  },
}

const SubscriptionsPage = () => {
  const [monthly, setMonthly] = useState(true)
  const [yearly, setYearly] = useState(false)
  //const [subscriptionsData, setSubscriptionsData] = useState<Subscription[]>([])
  const [openModalSuccess, setOpenModalSuccess] = useState(false)
  const [usd, setUsd] = useState(false)
  const [eur, setEur] = useState(false)
  const [chf, setChf] = useState(true)
  const [trySiotDisabled, setTrySiotDisabled] = useState(true)

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  useQuery({
    queryKey: ["subscriptionsData"],
    queryFn: () => dependencyContainer.dependency.subscriptionsRepositoryApi.subscriptions(),
    onSuccess: (data) => {
      if(data.length === 0) setTrySiotDisabled(false)
    },
  })

  // const unsubscribe = useMutation({
  //   mutationFn: () => dependencyContainer.dependency.subscriptionsRepositoryApi.unsubscribe(subscriptionsData?.[0].id as any),
  //   onSuccess: async (data) => {
  //     setOpenModalSuccess(true)
  //   },
  //   onError: (error: any) => {
  //     setTimeout(() => {
  //       console.log(error)
  //     }, 1500)
  //   },
  //   // onMutate: () => {
  //   //   setOpenLoader(true)
  //   // },
  // })

  const navigate = useNavigate()

  // const successUnsubscribe = () => {
  //   unsubscribe.mutate()
  // }

  const handleUsd = () => {
    setUsd(true)
    setEur(false)
    setChf(false)
  }
  const handleEur = () => {
    setEur(true)
    setUsd(false)
    setChf(false)
  }
  const handleChf = () => {
    setChf(true)
    setUsd(false)
    setEur(false)
  }

  const handleMonthly = () => {
    setMonthly(true)
    setYearly(false)
  }
  const handleYearly = () => {
    setYearly(true)
    setMonthly(false)
  }

  const currencyMap = () => {
    if (usd) {
      return "$"
    } else if (eur) {
      return "€"
    } else {
      return "CHF"
    }
  }

  const monthlyYearlyStarter = () => {
    if (monthly) {
      return (
        <GridContainer>
          <GridItem sx={styles.priceAmount}>{currencyMap()} 249</GridItem>
          <GridItem sx={{ mt: "10px", ml: "3px" }}> / month</GridItem>
        </GridContainer>
      )
    } else {
      return (
        <GridContainer>
          <GridItem sx={styles.priceAmount}>{currencyMap()} 199</GridItem>
          <GridItem sx={{ mt: "10px", ml: "3px" }}> / month</GridItem>
        </GridContainer>
      )
    }
  }
  const monthlyYearlyPremium = () => {
    if (monthly) {
      return (
        <GridContainer>
          <GridItem sx={styles.priceAmount}>{currencyMap()} 599</GridItem>
          <GridItem sx={{ mt: "10px", ml: "3px" }}> / month</GridItem>
        </GridContainer>
      )
    } else {
      return (
        <GridContainer>
          <GridItem sx={styles.priceAmount}>{currencyMap()} 499</GridItem>
          <GridItem sx={{ mt: "10px", ml: "3px" }}> / month</GridItem>
        </GridContainer>
      )
    }
  }
  const monthlyYearlyEnterprise = () => {
    return (
      <GridContainer>
        <GridItem sx={styles.priceAmount}>On request</GridItem>
      </GridContainer>
    )
  }

  const mailTo = () => {
    window.location.href = "mailto:info@appmodule.net"
  }

  return (
    <GridContainer sx={styles.grid}>
      <GridContainer>
        <Typography variant="body2" sx={{ fontSize: "26px", fontWeight: "bold", color: "gray" }}>
          Subscriptions
        </Typography>
        <Divider sx={{ width: "100%", mt: "10px", mb: "30px" }} />
        <GridContainer sx={{ justifyContent: "space-between" }}>
          <GridContainer sx={{ width: "400px" }}>
            <GridItem sx={styles.currencies1}>
              <ContainedButton
                onClick={handleMonthly}
                sx={{
                  backgroundColor: `${!monthly ? "#F2F4FA" : "#4472c4"}`,
                  color: `${!monthly && "black"}`,
                  width: "70px",
                  fontSize: "14px",
                  borderRadius: "10px",
                  mr: "4px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: `${!monthly && "#F2F4FA"}`,
                    color: `${!monthly && "#2196f3"}`,
                  },
                }}
              >
                Monthly
              </ContainedButton>
              <ContainedButton
                onClick={handleYearly}
                sx={{
                  backgroundColor: `${!yearly ? "#F2F4FA" : "#4472c4"}`,
                  color: `${!yearly && "black"}`,
                  width: "70px",
                  fontSize: "14px",
                  textTransform: "none",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: `${!yearly && "#F2F4FA"}`,
                    color: `${!yearly && "#2196f3"}`,
                  },
                }}
              >
                Yearly
              </ContainedButton>
            </GridItem>
            <GridItem sx={styles.currencies1} style={{ marginLeft: "auto" }}>
              <ContainedButton
                onClick={handleUsd}
                sx={{
                  backgroundColor: `${!usd ? "#F2F4FA" : "#4472c4"}`,
                  color: `${!usd && "black"}`,
                  width: "70px",
                  fontSize: "14px",
                  borderRadius: "10px",
                  mr: "4px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: `${!usd && "#F2F4FA"}`,
                    color: `${!usd && "#2196f3"}`,
                  },
                }}
              >
                USD
              </ContainedButton>
              <ContainedButton
                onClick={handleEur}
                sx={{
                  backgroundColor: `${!eur ? "#F2F4FA" : "#4472c4"}`,
                  color: `${!eur && "black"}`,
                  width: "70px",
                  fontSize: "14px",
                  borderRadius: "10px",
                  mr: "4px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: `${!eur && "#F2F4FA"}`,
                    color: `${!eur && "#2196f3"}`,
                  },
                }}
              >
                EUR
              </ContainedButton>
              <ContainedButton
                onClick={handleChf}
                sx={{
                  backgroundColor: `${!chf ? "#F2F4FA" : "#4472c4"}`,
                  color: `${!chf && "black"}`,
                  width: "70px",
                  fontSize: "14px",
                  textTransform: "none",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: `${!chf && "#F2F4FA"}`,
                    color: `${!chf && "#2196f3"}`,
                  },
                }}
              >
                CHF
              </ContainedButton>
            </GridItem>
          </GridContainer>
          <GridItem>
            {/* {subscriptionsData?.length === 0 ? ( */}
              <ContainedButton disabled={trySiotDisabled} onClick={() => navigate("payment")} sx={styles.button11} variant="outlined">
                Try SIOT
              </ContainedButton>
            {/* ) : (
              <ContainedButton onClick={successUnsubscribe} sx={styles.button11} variant="outlined">
                Unsubscribe
              </ContainedButton>
            )} */}
          </GridItem>
        </GridContainer>
      </GridContainer>
      <GridItem sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}></GridItem>
      <GridContainer sx={styles.container}>

        <GridItem sx={styles.outlineBox}>
          <GridItem sx={{ backgroundColor: "#a3a2a2", borderTopLeftRadius: "14px", borderTopRightRadius: "14px", color: "white", height: "140px", borderBottom: "5px solid white"  }}>
            <GridContainer sx={styles.heading}>
              <GridItem>
                <Typography variant="body2" sx={styles.typography}>
                  Starter
                </Typography>
                <Typography sx={{ fontSize: "15px", mb: "15px" }}>start your IoT business.</Typography>
              </GridItem>
              <SubscriptionPlanModal />
            </GridContainer>
          </GridItem>
          <GridItem sx={styles.content}>
            <GridItem sx={{ height: "205px", borderBottom: "5px solid white", px: "15px" }}>
              <Typography variant="body2" sx={styles.typography1}>
                Base fee
              </Typography>
              <GridItem sx={styles.price}>
                <GridItem>{monthlyYearlyStarter()}</GridItem>
              </GridItem>
              <Typography sx={{ fontSize: "15px", mt: "15px", mb: "5px", fontWeight: "bold" }}>Pay-per-use model for data</Typography>
              <Typography sx={{ fontSize: "15px", mb: "15px" }}>read & write operations and storage</Typography>
              <Typography sx={{ fontSize: "15px", mb: "25px", mt: "5px", pb: "10px" }}>fee billed monthly, excl. VAT</Typography>
            </GridItem>
            <GridItem sx={styles.list}>
              <GridItem sx={styles.listTitle}>Features:</GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>Private Cloud hosted in Appmodule data center Switzerland</GridItem>
              </GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>Generic visualization app (web, mobile)</GridItem>
              </GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>Dashboard builder</GridItem>
              </GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>User management</GridItem>
              </GridItem>
            </GridItem>
            <GridItem sx={styles.list1}>
              <GridItem sx={styles.listTitle}>Support:</GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>5x8h support</GridItem>
              </GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>IoT solution design call (30 min)</GridItem>
              </GridItem>
            </GridItem>
          </GridItem>
          <Stack spacing={2} direction="row" sx={styles.buttonStack}>
            <Button onClick={() => mailTo()} sx={styles.button} variant="contained">
              Contact sales
            </Button>
          </Stack>
        </GridItem>

        <GridItem sx={styles.outlineBox}>
          <GridItem sx={{ backgroundColor: "#4472c4", borderTopLeftRadius: "14px", borderTopRightRadius: "14px", color: "white", height: "140px", borderBottom: "5px solid white"  }}>
            <GridContainer sx={styles.heading}>
              <GridItem>
                <Typography variant="body2" sx={styles.typography}>
                  Premium
                </Typography>
                <Typography sx={{ fontSize: "15px", mb: "15px" }}>enjoy the beauty of IoT.</Typography>
              </GridItem>
              <SubscriptionPlanModal />
            </GridContainer>
          </GridItem>
          <GridItem sx={styles.content}>
            <GridItem sx={{ height: "205px",borderBottom: "5px solid white", px: "15px", pb: "10px" }}>
              <Typography variant="body2" sx={styles.typography1}>
                Base fee
              </Typography>
              <GridItem sx={styles.price}>
                <GridItem>{monthlyYearlyPremium()}</GridItem>
              </GridItem>
              <Typography sx={{ fontSize: "15px", mt: "15px", mb: "5px", fontWeight: "bold" }}>Pay-per-use model for data</Typography>
              <Typography sx={{ fontSize: "15px", mb: "15px" }}>read & write operations and storage</Typography>
              <Typography sx={{ fontSize: "15px", mb: "25px", mt: "5px",pb: "10px" }}>fee billed monthly, excl. VAT</Typography>
            </GridItem>
            <GridItem sx={styles.list}>
              <GridItem sx={styles.listTitle}>Features:</GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>All Starter features</GridItem>
              </GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>Widget customization</GridItem>
              </GridItem>
            </GridItem>
            <GridItem sx={styles.list1}>
              <GridItem sx={styles.listTitle}>Support:</GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>7x8h support</GridItem>
              </GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>IoT solution design call (60 min)</GridItem>
              </GridItem>
            </GridItem>
          </GridItem>
          <Stack spacing={2} direction="row" sx={styles.buttonStack}>
            <Button onClick={() => mailTo()} sx={styles.button} variant="contained">
              Contact sales
            </Button>
          </Stack>
        </GridItem>

        <GridItem sx={styles.outlineBox}>
          <GridItem sx={{ backgroundColor: "#a3a2a2", borderTopLeftRadius: "14px", borderTopRightRadius: "14px", color: "white",height: "140px", borderBottom: "5px solid white" }}>
            <GridContainer sx={styles.heading}>
              <GridItem>
                <Typography variant="body2" sx={styles.typography}>
                  Enterprise
                </Typography>
                <Typography sx={{ fontSize: "15px", mb: "15px" }}>let's talk about your on-prem IoT solution.</Typography>
              </GridItem>
              <SubscriptionPlanModal />
            </GridContainer>
          </GridItem>
          <GridItem sx={styles.content}>
            <GridItem sx={{ height: "205px",borderBottom: "5px solid white", px: "15px" }}>
              <GridItem sx={styles.price}>
                <GridItem>{monthlyYearlyEnterprise()}</GridItem>
              </GridItem>
            </GridItem>
            <GridItem sx={styles.list}>
              <GridItem sx={styles.listTitle}>Features:</GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>All Premium features</GridItem>
              </GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>Private cloud hosted on-prem or in the data center of your choice</GridItem>
              </GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>White labeling</GridItem>
              </GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>Customized visualization apps (web, mobile)</GridItem>
              </GridItem>
            </GridItem>
            <GridItem sx={styles.list1}>
              <GridItem sx={styles.listTitle}>Support:</GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>24/7 support</GridItem>
              </GridItem>
              <GridItem sx={styles.listItem}>
                <GridItem sx={styles.listIcon}>
                  <CheckCircleIcon sx={{ fontSize: 22 }} />
                </GridItem>
                <GridItem sx={styles.listText}>Professional services</GridItem>
              </GridItem>
            </GridItem>
          </GridItem>
          <Stack spacing={2} direction="row" sx={styles.buttonStack}>
            <Button onClick={() => mailTo()} sx={styles.button} variant="contained">
              Contact sales
            </Button>
          </Stack>
        </GridItem>
      </GridContainer>
      {openModalSuccess && <SuccessModal setOpenModalSuccess={setOpenModalSuccess} openModalSuccess={openModalSuccess} />}
    </GridContainer>
  )
}

export default SubscriptionsPage
