import React, { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import List from "@mui/material/List"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { useLocation } from "react-router-dom"
import { Collapse, ListItemButton, SvgIconProps, Typography } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import GridItem from "../../basic/grid/GridItem"
import { useLanguage } from "../../../languageContext"
import { AppTheme } from "../../../AppTheme"
import DependencyContainer from "../../../DependencyContainer"
import { useQuery } from "@tanstack/react-query"
import { Subscription } from "../../../repository/SubscriptionsRepository"

interface Route {
  name: string
  route: string
  icon: React.ReactElement<SvgIconProps>
}

interface Element {
  name: string
  route: string
  icon: React.ReactElement<SvgIconProps>
  subitems?: Route[]
}

interface Props {
  routes: Element[]
  open: boolean
}

const styles = {
  activeRoute: {
    backgroundColor: "",
    color: AppTheme.palette.primary.main,
    "&:hover": {
      backgroundColor: "",
      transition: ".4s ease",
      color: AppTheme.palette.primary.main,
    },
  },
  activeSubRoute: {
    backgroundColor: AppTheme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: AppTheme.palette.primary.main,
      transition: ".4s ease",
      color: "white",
    },
  },
  route: {
    color: "white",
    "&:hover": {
      backgroundColor: "",
      transition: ".4s ease",
      color: "white",
    },
  },
  subRoute: {
    color: "white",
    "&:hover": {
      backgroundColor: AppTheme.palette.primary.main,
      transition: ".4s ease",
      color: "white",
    },
  },
  activeIcon: {
    paddingLeft: "3px",
    color: AppTheme.palette.primary.main,
  },
  icon: {
    paddingLeft: "3px",
    color: "white",
  },
  header: {
    backgroundColor: "transparent",
    color: "#a8acb5",
    fontSize: "13px",
    ml: "-7px",
    textTransform: "uppercase",
    mb: "40px",
  },
}

const SideBarBillingItems = ({ routes, open }: Props) => {
  const [openIndex, setOpenIndex] = useState(-1)
  const [subscriptionsData, setSubscriptionsData] = useState<Subscription[] | undefined>()

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  useQuery({
    queryKey: ["subscriptionsData"],
    queryFn: () => dependencyContainer.dependency.subscriptionsRepositoryApi.subscriptions(),
    onSuccess: (data) => {
      setSubscriptionsData(data)
    },
  })
  const location = useLocation()
  const { t } = useLanguage()

  const handleClick = (index: number) => {
    setOpenIndex(openIndex === index ? -1 : index)
  }

  const isActiveRoute = (route: string) => {
    return location.pathname === route
  }

  if (subscriptionsData?.length === 0) {
    routes = routes.filter((route) => route.name !== "Overview")
  }

  return (
    <>
      <List component="nav" aria-labelledby="nested-list-subheader">
        {routes.map((item: Element, index: number) => (
          <Fragment key={index}>
            {item.subitems ? (
              <Fragment>
                <ListItemButton onClick={() => handleClick(index)} sx={isActiveRoute(item.route) ? styles.activeRoute : styles.route}>
                  <ListItemIcon sx={isActiveRoute(item.route) ? styles.activeIcon : styles.icon}>{item.icon}</ListItemIcon>
                  <ListItemText
                    sx={{ opacity: open ? 1 : 0 }}
                    primary={
                      <Typography variant="body2" style={{ fontSize: "14px" }}>
                        {t(item.name)}
                      </Typography>
                    }
                  />
                  <GridItem sx={{ opacity: open ? 1 : 0, position: "absolute", left: "175px", top: "10px" }}>
                    {open ? openIndex === index ? <ExpandLess /> : <ExpandMore /> : null}
                  </GridItem>
                </ListItemButton>
                <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subitems.map((subroute: Route, subIndex: number) => (
                      <ListItemButton
                        component={Link}
                        to={subroute.route}
                        key={`${index}-${subIndex}`}
                        sx={isActiveRoute(subroute.route) ? styles.activeSubRoute : styles.subRoute}
                      >
                        <ListItemIcon sx={{ color: "white", pl: "5px" }}>{subroute.icon}</ListItemIcon>
                        <ListItemText
                          sx={{ opacity: open ? 1 : 0 }}
                          primary={
                            <Typography variant="body2" style={{ fontSize: "14px" }}>
                              {t(subroute.name)}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              <ListItemButton component={Link} to={item.route} sx={isActiveRoute(item.route) ? styles.activeRoute : styles.route}>
                <ListItemIcon sx={isActiveRoute(item.route) ? styles.activeIcon : styles.icon}>{item.icon}</ListItemIcon>
                <ListItemText
                  sx={{ opacity: open ? 1 : 0 }}
                  primary={
                    <Typography variant="body2" style={{ fontSize: "14px" }}>
                      {t(item.name)}
                    </Typography>
                  }
                />
              </ListItemButton>
            )}
          </Fragment>
        ))}
      </List>
    </>
  )
}

export default SideBarBillingItems
