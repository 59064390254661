import * as React from "react"
import Typography from "@mui/material/Typography"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import { Link } from "react-router-dom"
import "./breadcrumb-style.css"

interface BreadcrumbProps {
  link: string
  page: string 
}

const styles = {
  dashboard: {
    fontSize: "26px",
    fontWeight: "bold",
    textDecoration: "none",
    color: "gray"
  },
}

const mapLink = (link: string) => {
  if (link === "billing") {
    return "Billing"
  }
  else if (link === "subscriptions") {
    return "Subscriptions"
  }
}

const Breadcrumb = ({ link, page }: BreadcrumbProps) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={styles.dashboard}>
      <Link className='breadcrumb-link' to={`/${link}`}>
      {mapLink(link)}
      </Link>
      <Typography sx={styles.dashboard}>{page}</Typography>
    </Breadcrumbs>
  )
}
export default Breadcrumb
