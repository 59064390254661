import React, { Fragment, useState } from "react"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Logout from "@mui/icons-material/Logout"
import GridItem from "../grid/GridItem"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import Badge from "@mui/material/Badge"
import { styled } from "@mui/material/styles"
import { useLanguage } from "../../../languageContext"
//import AuthService from "../../../repository/AuthService"
import { jwtDecode } from "jwt-decode"
import { AppTheme } from "../../../AppTheme"
import AppKeycloak, { KeycloakStore } from "../../../Keycloak"

interface JwtPayload {
  email: string
  name: string
}

const styles = {
  iconTextHolder: {
    borderRadius: "25px",
    width: "auto",
    height: "43px",
    display: "flex",
    ml: "auto",
    cursor: "pointer",
    mb: "2px",
  },
  iconText: {
    width: "auto",
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#666666",
  },
  menuItem: {
    "&:hover": {
      background: AppTheme.palette.primary.light,
    },
  },
  menuItemInactive: {
    color: "#9d9d9e",
    cursor: "not-allowed",
    "&:hover": {
      background: "transparent",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      width: "230px",
    },
    ml: "18px",
  },
  notificationBadge: {
    ml: "auto",
    mr: "16px",
  },
}

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { t } = useLanguage()

  const token = localStorage.getItem("token") as string
  const decodedToken = token !== null ? jwtDecode(token) as JwtPayload : null

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: AppTheme.palette.primary.dark,
      },
      children: `${name}` !== "" ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`: "",
    }
  }

  const logout = async () => {
  
    // await AppKeycloak.getAppKeycloak().logout()
		// 	.then(() => {
		// 		console.log('LOGGED OUT')
		// 		window.location.replace('/')
		// 	})
		// 	.catch((err) => {
		// 		console.log('ERR', err)
		// 	})
    new Promise<KeycloakStore>((resolve, reject) => {
      const keycloak = AppKeycloak.getAppKeycloak()
      keycloak
        .start()
        .then(() => {
          resolve(keycloak.store())
        })
        .catch((e) => {
          // reject(e)
          console.log(e)
        })
        .then(() => {
          AppKeycloak.getAppKeycloak().logout()
        })
    })

  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }))

  return (
    <Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", marginLeft: "10px" }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={styles.iconTextHolder}
          style={{ marginLeft: "-15px" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <GridItem sx={styles.iconText}>
              <StyledBadge overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }} variant="dot">
              <Avatar {...stringAvatar(decodedToken ? decodedToken?.name : "")} />
              </StyledBadge>
          </GridItem>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={styles.menu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={styles.menuItemInactive}>
          <ListItemIcon sx={{ color: "#9d9d9e" }}>
            <AccountCircleOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2" sx={{ fontSize: "15px" }}>
            {t("myProfile")}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem sx={styles.menuItemInactive}>
          <ListItemIcon sx={{ color: "#9d9d9e" }}>
            <SettingsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2" sx={{ fontSize: "15px" }}>
            {t("acountSettings")}
          </Typography>
        </MenuItem>
        <MenuItem onClick={logout} sx={styles.menuItem}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2" sx={{ fontSize: "15px" }}>
            {t("logout")}
          </Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}
