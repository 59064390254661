import React from "react"
import SearchIcon from "@mui/icons-material/Search"
import TextField from "@mui/material/TextField"
import GridItem from "../grid/GridItem"
import CloseIcon from "@mui/icons-material/Close"

interface Props {
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  searchWord: string
  resetSearchWord: () => void
}

const styles = {
  iconStyle: {
    cursor: "pointer",
    color: "#bab6b6",
  },
  iconStyle2: {
    color: "#bab6b6",
  },
}

const Search = ({ handleSearch, searchWord, resetSearchWord }: Props) => {

  return (
    <GridItem>
      <TextField
        id="outlined-required"
        size="small"
        fullWidth
        value={searchWord}
        onChange={handleSearch}
        InputLabelProps={{
          style: { fontSize: "14px", letterSpacing: "1px" },
        }}
        InputProps={{
          endAdornment: searchWord === "" ? <SearchIcon sx={styles.iconStyle2} /> : <CloseIcon sx={styles.iconStyle} onClick={resetSearchWord} />,
        }}
      />
    </GridItem>
  )
}

export default Search
