import React from "react"
import { Navigate } from "react-router-dom"

import { KeycloakStore } from "../Keycloak"

interface Props {
  keycloakStore: KeycloakStore
}

export default function AppLoader({ keycloakStore }: Props) {

  const { keycloak, authenticated } = keycloakStore

  console.log(keycloakStore)

  if (keycloak !== null) {
    if (authenticated) return <Navigate to="/subscription-overview" /> // successful authenticated
    else return <Navigate to="/" /> // no authenticated
  }
  return <p>LOADING....</p>
}
