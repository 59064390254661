import React from "react"
import GridItem from "../../components/basic/grid/GridItem"
import { Typography } from "@mui/material"
import GridContainer from "../../components/basic/grid/GridContainer"
import { useLanguage } from "../../languageContext"

const styles = {
  container1070: {
    width: { xs: "100%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
    maxWidth: { xs: "100%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
    margin: "0 auto",
    mb: "15px",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
    mt: "50px",
    // px: { xs: 1, sm: 2, md: 10, lg: 10, xl: 10 },
  },
  subTitle2: {
    fontWeight: "bold",
    fontSize: "20px",
    marginBottom: "10px",
    color: "#868686",
  },
  sectionHeading: {
    fontSize: { xs: 24, sm: 24, md: 32, lg: 38, xl: 38 },
    fontWeight: "bold",
    mb: "30px",
  },
  text: {
    fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 },
    lineHeight: 2,
  },
  textBold: {
    fontSize: { xs: 13, sm: 16, md: 19, lg: 19, xl: 19 },
    lineHeight: 2,
    fontWeight: "bold",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    width: "100%",
    p: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3 },
  },
}

const ImpressumPage = () => {
  const { t } = useLanguage()

  return (
    <GridContainer sx={styles.grid}>
      <GridItem sx={styles.container1070}>
        <Typography variant="body2" sx={styles.sectionHeading}>
          {t("impressum")}
        </Typography>
        <Typography variant="body2" sx={styles.textBold}>
          {t("LegalNotice")}
        </Typography>
        <GridContainer sx={{ mt: "40px" }}>
          <GridItem sx={{ width: "250px" }}>
            <Typography variant="body2" sx={styles.textBold}>
              {t("address")}
            </Typography>
          </GridItem>
          <GridItem sx={{ width: "370px" }}>
            <Typography variant="body2" sx={styles.text}>
              Appmodule AG
            </Typography>
            <Typography variant="body2" sx={styles.text}>
              Meriedweg 11
            </Typography>
            <Typography variant="body2" sx={styles.text}>
              3172 Niederwangen
            </Typography>
            <Typography variant="body2" sx={styles.text}>
              {t("switzerland")}
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer sx={{ mt: "20px" }}>
          <GridItem sx={{ width: "250px" }}>
            <Typography variant="body2" sx={styles.textBold}>
              {t("shippingAddress")}
            </Typography>
          </GridItem>
          <GridItem sx={{ width: "370px" }}>
            <Typography variant="body2" sx={styles.text}>
              Appmodule AG
            </Typography>
            <Typography variant="body2" sx={styles.text}>
              Aarbergstrasse 46
            </Typography>
            <Typography variant="body2" sx={styles.text}>
              2503 Biel/Bienne {t("switzerland")}
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer sx={{ mt: "20px" }}>
          <GridItem sx={{ width: "250px" }}>
            <Typography variant="body2" sx={styles.textBold}>
              {t("managementBoard")}
            </Typography>
          </GridItem>
          <GridItem sx={{ width: "370px" }}>
            <Typography variant="body2" sx={styles.text}>
              {t("boardPresident")}
            </Typography>
            <Typography variant="body2" sx={styles.text}>
              CEO: Anton Guggenhuber
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer sx={{ mt: "20px" }}>
          <GridItem sx={{ width: "250px" }}>
            <Typography variant="body2" sx={styles.textBold}>
              {t("telephone")}
            </Typography>
          </GridItem>
          <GridItem sx={{ width: "370px" }}>
            <Typography variant="body2" sx={styles.text}>
              +41 44 998 00 60
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer sx={{ mt: "20px" }}>
          <GridItem sx={{ width: "250px" }}>
            <Typography variant="body2" sx={styles.textBold}>
              E-Mail
            </Typography>
          </GridItem>
          <GridItem sx={{ width: "370px" }}>
            <Typography variant="body2" sx={styles.text}>
              info@appmodule.net
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer sx={{ mt: "20px" }}>
          <GridItem sx={{ width: "250px" }}>
            <Typography variant="body2" sx={styles.textBold}>
              {t("companyRegister")}
            </Typography>
          </GridItem>
          <GridItem sx={{ width: "370px" }}>
            <Typography variant="body2" sx={styles.text}>
              Name: Appmodule AG
            </Typography>
            <Typography variant="body2" sx={styles.text}>
              {t("number")}: CHE-280.671.202
            </Typography>
            <Typography variant="body2" sx={styles.text}>
              {t("court")}: Bern
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer sx={{ mt: "20px" }}>
          <GridItem sx={{ width: "250px" }}>
            <Typography variant="body2" sx={styles.textBold}>
              VAT-ID-Nr.
            </Typography>
          </GridItem>
          <GridItem sx={{ width: "370px" }}>
            <Typography variant="body2" sx={styles.text}>
              CHE-280.671.202 MWST
            </Typography>
          </GridItem>
        </GridContainer>
        <Typography variant="body2" sx={styles.textBold} style={{ marginTop: "50px" }}>
          {t("exclusion")}
        </Typography>
        <Typography variant="body2" sx={styles.text} style={{ marginTop: "10px" }}>
          {t("impresumText1")}
        </Typography>
        <Typography variant="body2" sx={styles.text} style={{ marginTop: "10px" }}>
          {t("impresumText2")}
        </Typography>
        <Typography variant="body2" sx={styles.text} style={{ marginTop: "10px" }}>
          {t("impresumText3")}
        </Typography>
        <Typography variant="body2" sx={styles.text} style={{ marginTop: "10px" }}>
          {t("impresumText4")}
        </Typography>
        <Typography variant="body2" sx={styles.text} style={{ marginTop: "10px" }}>
          {t("impresumText5")}
        </Typography>
        <Typography variant="body2" sx={styles.text} style={{ marginTop: "10px" }}>
          {t("impresumText6")}
        </Typography>
        <Typography variant="body2" sx={styles.text} style={{ marginTop: "10px" }}>
          {t("impresumText7")}
        </Typography>
      </GridItem>
    </GridContainer>
  )
}
export default ImpressumPage
