import axios from "axios"
import PaymentRepository, { PaymentHistory, PaymentHistoryUnmapped } from "./PaymentRepository"

export default class APIPaymentRepository implements PaymentRepository {
  async paymentHistory(): Promise<PaymentHistory[]> {
    const settings = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")
    try {
      const data = await axios.get(`${settings.IOTOOLS_BUSINESS_PORTAL_BACKEND_URL}/v1/payment-history`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data.data?.data?.map((payment: PaymentHistoryUnmapped) => this.mapPaymentHistory(payment))
    } catch (error) {
      console.log(error)
      return []
    }
  }

  mapPaymentHistory(responseData: PaymentHistoryUnmapped) {
    return {
      id: responseData.id,
      userId: responseData.user_id,
      paymentMethod: responseData.method,
      paymentTransactionId: responseData.transaction_id,
      receiptDate: responseData.date,
      paymentAmount: responseData.amount,
      paymentCurrency: responseData.currency,
    }
  }
}
