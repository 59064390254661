import React, { useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { styled, Theme, CSSObject } from "@mui/material/styles"
import Box from "@mui/material/Box"
import MuiDrawer from "@mui/material/Drawer"
import CssBaseline from "@mui/material/CssBaseline"
import IconButton from "@mui/material/IconButton"
import AppBar from "../appBar/AppBar"
import SyncAltIcon from "@mui/icons-material/SyncAlt"
import { businessRoutes } from "./Routes"
import { SvgIconProps } from "@mui/material"
import GridItem from "../../basic/grid/GridItem"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import SideBarBillingItems from "./SideBarBillingItems"
import { ReactComponent as SiotLogo } from "../../../assets/images/siotLogo.svg"
import sideBackground from "../../../assets/images/sidebar-bg_black.jpg"
// import Agreement from "../../basic/agreement/Agreement"

interface Route {
  name: string
  route: string
  icon: React.ReactElement<SvgIconProps>
}

interface Element {
  name: string
  route: string
  icon: React.ReactElement<SvgIconProps>
  subitems?: Route[]
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const drawerWidth = 250

const styles = {
  drawer: {
    height: "100%",
    display: "flex",
  },
  iconButton: {
    ml: "auto",
    mr: "8px",
    color: "white",
    "&:hover": {
      backgroundColor: "#454b54",
      transition: ".4s ease",
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  appBar: {
    height: "75px",
    fontSize: "15px",
  },
  body: {
    flexGrow: 1,
    p: 3,
    //height: "100vh",
  },
  logo: {
    width: "135px",
    ml: "10px",
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
}

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundImage: `url(${sideBackground})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: window.screen.width < 600 ? `calc(${theme.spacing(5)} + 24px)` : drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundImage: `url(${sideBackground})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  [theme.breakpoints.up("xs")]: {
    width: window.screen.width < 600 ? "0px" : `calc(${theme.spacing(5)} + 24px)`,
  },
})

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  color: "white",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  overflow: "hidden",
  flexShrink: 0,
  height: "100vh",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

const Appbar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter,
  }),
  marginLeft: open ? drawerWidth : "80px",
  width: window.screen.width < 600 ? "100%" : `calc(100% - ${open ? drawerWidth : 65}px)`,
  background: "#ffffff",
}))

const SideBar = () => {
  const [open, setOpen] = useState(window.screen.width < 600 ? false : true)

  const navigate = useNavigate()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Appbar sx={styles.appBar} elevation={3} open={open}>
        <AppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      </Appbar>
      <Drawer variant="permanent" open={open} sx={styles.drawer}>
        <DrawerHeader sx={styles.drawerHeader}>
          <GridItem sx={styles.logo} onClick={() => navigate("/subscription-overview")}>
            <SiotLogo />
          </GridItem>
          <IconButton sx={styles.iconButton} onClick={open ? handleDrawerClose : handleDrawerOpen}>
            <SyncAltIcon />
          </IconButton>
        </DrawerHeader>
        <GridItem sx={{ mt: "100px" }}>
          <SideBarBillingItems routes={businessRoutes as Element[]} open={open} />
        </GridItem>
      </Drawer>
      <Box component="main" sx={styles.body}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  )
}

export default SideBar
