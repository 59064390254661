import axios from "axios"
import VouchersRepository, { VoucherType, VoucherCheckUseType, VouchersUnmapped, VoucherCheckUseUnmapped } from "./VouchersRepository"

export default class APIVouchersRepository implements VouchersRepository {
  async vouchers(): Promise<VoucherType[]> {
    const settings = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")
    try {
      const data = await axios.get(`${settings.IOTOOLS_BUSINESS_PORTAL_BACKEND_URL}/v1/vouchers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data.data?.data?.map((voucher: VouchersUnmapped) => this.mapVouchers(voucher))
    } catch (error) {
      console.log(error)
      return []
    }
  }

  async checkVoucher(voucherCode: string): Promise<VoucherCheckUseType> {
    const settings = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")
    const data = await axios.get(`${settings.IOTOOLS_BUSINESS_PORTAL_BACKEND_URL}/v1/voucher/check?code=${voucherCode}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return this.mapVoucherCheckUse(data.data?.voucher)
  }

  async useVoucher(voucherCode: string): Promise<VoucherCheckUseType> {
    const settings = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")
    const data = await axios.get(`${settings.IOTOOLS_BUSINESS_PORTAL_BACKEND_URL}/v1/voucher/use?code=${voucherCode}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return this.mapVoucherCheckUse(data.data?.voucher)
  }

  mapVouchers(responseData: VouchersUnmapped) {
    return {
      id: responseData.id,
      userId: responseData.user_id,
      type: responseData.type,
      code: responseData.code,
      oneTime: responseData.one_time,
      activationDate: responseData.activation_date,
      dueDate: responseData.due_date,
      used: responseData.used,
      serviceId: responseData.service_id,
      value: responseData.value,
      discount: responseData.discount,
      discountedValue: responseData.discounted_value,
      date: responseData.date,
      description: responseData.description,
      currency: responseData.currency,
    }
  }

  mapVoucherCheckUse(responseData: VoucherCheckUseUnmapped) {
    return {
      serviceId: responseData.service_id,
      code: responseData.code,
      dueDate: responseData.due_date,
      description: responseData.description,
      price: responseData.price,
    }
  }
}
