import axios from "axios"
import SubscriptionsRepository, { Subscription, SubscriptionUnmapped } from "./SubscriptionsRepository"
//import AppKeycloak from "../Keycloak"

export default class APISubscriptionsRepository implements SubscriptionsRepository {
  async subscriptions(): Promise<Subscription[]> {
    const settings = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")
    try {
      const data = await axios.get(`${settings.IOTOOLS_BUSINESS_PORTAL_BACKEND_URL}/v1/subscriptions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data.data?.data?.map((subscription: SubscriptionUnmapped) => this.mapSubscriptions(subscription))
    } catch (error) {
      console.log(error)
      return []
    }
  }

  async unsubscribe(sibscriptionId: string): Promise<[]> {
    const settings = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")
    const data = await axios.delete(`${settings.IOTOOLS_BUSINESS_PORTAL_BACKEND_URL}/v1/subscriptions/${sibscriptionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data.data?.data
  }

  mapSubscriptions(responseData: SubscriptionUnmapped) {
    return {
      autoRenewal: responseData.auto_renewal,
      billingAddress: responseData.billing_address,
      billingCycle: responseData.billing_cycle,
      costs: responseData.costs,
      creationDate: responseData.creation_date,
      endDate: responseData.end_date,
      id: responseData.id,
      notifications: responseData.notifications,
      paymentMethod: responseData.payment_method,
      readMessagesEvents: responseData.read_messages_events,
      serviceId: responseData.service_id,
      serviceName: responseData.service_name,
      startDate: responseData.start_date,
      status: responseData.status,
      storage: responseData.storage,
      subscriptionId: responseData.subscription_id,
      totalLicences: responseData.total_licences,
      type: responseData.type,
      userId: responseData.user_id,
      writeMessagesEvents: responseData.write_messages_events,
      usage: responseData.usage,
      cost: responseData.cost,
    }
  }
}
