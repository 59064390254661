import APIInvoicesRepository from './repository/APIInvoicesRepository'
import InvoicesRepository from './repository/InvoicesRepository'
import APIPaymentRepository from './repository/APIPaymentRepository'
import PaymentRepository from './repository/PaymentRepository'
import APIVouchersRepository from './repository/APIVouchersRepository'
import VouchersRepository from './repository/VouchersRepository'
import APISubscriptionsRepository from './repository/APISubscriptionsRepository'
import SubscriptionsRepository from './repository/SubscriptionsRepository'
import APIUserRepository from './repository/APIUserRepository'
import UserRepository from './repository/UserRepository'

export interface Dependency {
  invoicesRepositoryApi: InvoicesRepository
	paymentRepositoryApi: PaymentRepository
	vouchersRepositoryApi: VouchersRepository
	subscriptionsRepositoryApi: SubscriptionsRepository
	userRepositoryApi: UserRepository
}

export default class DependencyContainer {

	private _dependency!: Dependency

	createDependency(): void {

		this._dependency = {
			invoicesRepositoryApi: new APIInvoicesRepository(),
			paymentRepositoryApi: new APIPaymentRepository(),
			vouchersRepositoryApi: new APIVouchersRepository(),
			subscriptionsRepositoryApi: new APISubscriptionsRepository(),
			userRepositoryApi: new APIUserRepository(),
		}
	}
	get dependency(): Dependency {
		return this._dependency
	}
}
