import axios from "axios"
import InvoicesRepository, { Invoice, InvoiceUnmapped } from "./InvoicesRepository"

export default class APIInvoicesRepository implements InvoicesRepository {
  async invoices(): Promise<Invoice[]> {
    const settings = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")
    try {
      const data = await axios.get(`${settings.IOTOOLS_BUSINESS_PORTAL_BACKEND_URL}/v1/invoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data.data?.data?.map((invoice: InvoiceUnmapped) => this.mapInvoices(invoice))
    } catch (error) {
      console.log(error)
      return []
    }
  }

  mapInvoices(responseData: InvoiceUnmapped) {
    return {
      id: responseData.id,
      userId: responseData.user_id,
      invoiceId: responseData.invoice_no,
      invoiceDate: responseData.invoice_date,
      dueDate: responseData.due_date,
      total: responseData.total,
      status: responseData.status,
    }
  }
}
