import GridItem from "../../basic/grid/GridItem"
import GridContainer from "../../basic/grid/GridContainer"
import AccountMenu from "../../basic/menu/AccountMenu"
import { IconButton, Typography } from "@mui/material"
import LanguageMenu from "../../basic/menu/LanguageMenu"
//import { useLanguage } from "../../../languageContext"
import NotificationsMenu from "../../basic/menu/NotificationsMenu"
import SyncAltIcon from "@mui/icons-material/SyncAlt"

interface Props {
  open: boolean
  handleDrawerOpen: any
}

const styles = {
  icons1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ml: "auto",
    mr: "25px",
    height: "75px",
  },
  iconButton: {
    mr: "auto",
    ml: "10px",
  },
}

const AppBar = ({ handleDrawerOpen, open }: Props) => {
  //const { t } = useLanguage()

  return (
    <GridItem sx={{ height: "75px" }}>
      <GridContainer style={{ justifyContent: "space-between" }}>
        {window.screen.width < 600 && !open && (
          <IconButton sx={styles.iconButton} onClick={handleDrawerOpen}>
            <SyncAltIcon />
          </IconButton>
        )}
        <GridItem sx={{ ml: "25px", my: "auto" }}>
          <Typography variant="body2" sx={{ mt: "3px", mr: "25px", fontSize: "18px", color: "gray", fontWeight: "bold" }}>
            SIOT Business Portal
          </Typography>
        </GridItem>
        <GridItem sx={styles.icons1}>
          <LanguageMenu />
          <NotificationsMenu />
          <AccountMenu />
        </GridItem>
      </GridContainer>
    </GridItem>
  )
}
export default AppBar
