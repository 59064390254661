import React, { useEffect, useState } from "react"
import GridContainer from "../../components/basic/grid/GridContainer"
import { Button, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import { Subscription } from "../../repository/SubscriptionsRepository"
import { AppTheme } from "../../AppTheme"
import GridItem from "../../components/basic/grid/GridItem"
import { useNavigate } from "react-router-dom"
import DependencyContainer from "../../DependencyContainer"
import { useQuery } from "@tanstack/react-query"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import SendOutlinedIcon from "@mui/icons-material/SendOutlined"
import visa1 from "../../assets/images/visa2.png"
import paypal from "../../assets/images/paypal.png"
import voucher from "../../assets/images/voucher.png"
import PriceBreakdownModal from "../../components/advanced/modals/PriceBreakdownModal"
import PaymentHistory from "../../components/advanced/payment/PaymentHistory"

const styles = {
  cards: {
    minWidth: "500px",
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  expandIcon: {
    color: "black",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    mt: "20px",
    flex: 1.5,
    height: "250px",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    minWidth: "450px",
  },
  paper1: {
    mt: "20px",
    flex: 1,
    height: "250px",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    minWidth: "450px",
  },
  paper2: {
    mt: "20px",
    flex: 1,
    height: "360px",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    minWidth: "450px",
    p: "25px",
  },
  paper3: {
    mt: "20px",
    flex: 1,
    height: "100%",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    minWidth: "450px",
    p: "25px",
  },
  divider: {
    flex: 1,
    my: "auto",
    minWidth: "200px",
    height: "10px",
    background: AppTheme.palette.primary.light,
  },
  link1: {
    cursor: "pointer",
    fontSize: "16px",
    color: AppTheme.palette.primary.dark,
    pl: "5px",
    "&:hover": {
      color: AppTheme.palette.primary.main,
    },
  },
  linkDisabled: {
    //cursor: "pointer",
    fontSize: "16px",
    color: "gray",
    pl: "5px",
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    mr: "25px",
    borderRadius: "20px",
    color: AppTheme.palette.primary.dark,
    border: `1px solid ${AppTheme.palette.primary.dark}`,
    "&:hover": {
      color: AppTheme.palette.primary.main,
      border: `1px solid ${AppTheme.palette.primary.main}`,
    },
  },
  sendIcon: {
    cursor: "pointer",
    color: AppTheme.palette.primary.main,
    mt: "20px",
    ml: "5px",
  },
  sendIcon1: {
    cursor: "pointer",
    color: AppTheme.palette.primary.main,
    mt: "7px",
    ml: "5px",
  },
}

const BillingPage = () => {
  const [subscriptionsData, setSubscriptionsData] = useState<Subscription[]>([])
  const [showAddressInput, setShowAddressInput] = useState(false)
  const [billingAddress, setBillingAddress] = useState("")
  const [showBillingMethodInput, setShowBillingMethodInput] = useState(false)
  const [billingMethod, setBillingMethod] = useState("")
  const [showBillingHistory, setShowBillingHistory] = useState(false)

  const navigate = useNavigate()

  const scrollToPosition = () => {
    setShowBillingHistory(true)
    setTimeout(() => {
      window.scrollTo({
        top: 600,
        behavior: "smooth",
      })
    }, 500)
  }

  const handleChangeBillingAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBillingAddress(event.target.value)
  }
  window.localStorage.setItem("billing-address", billingAddress)

  const handleChangeBillingMethod = (event: SelectChangeEvent) => {
    setBillingMethod(event.target.value)
  }

  useEffect(() => {
    setBillingMethod(subscriptionsData[0]?.paymentMethod || "")
    setBillingAddress(subscriptionsData[0]?.billingAddress || "")
  }, [subscriptionsData])

  const imageMap = () => {
    if (billingMethod === "Voucher") {
      return <img src={voucher} alt="voucher" style={{ width: "80px", height: "50px" }} />
    } else if (billingMethod === "CreditCard") {
      return <img src={visa1} alt="visa" style={{ width: "80px", height: "50px" }} />
    } else if (billingMethod === "PayPal") {
      return <img src={paypal} alt="paypal" style={{ width: "80px", height: "50px" }} />
    }
  }

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  useQuery({
    queryKey: ["subscriptionsData"],
    queryFn: () => dependencyContainer.dependency.subscriptionsRepositoryApi.subscriptions(),
    onSuccess: (data) => {
      setSubscriptionsData(data)
    },
  })

  const openInputField = () => {
    setShowAddressInput(true)
  }

  const openSelectField = () => {
    setShowBillingMethodInput(true)
  }

  const monthAbbreviations = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

  const monthAbbrevStart = monthAbbreviations[new Date(parseInt(subscriptionsData[0]?.startDate)).getMonth()]
  const monthAbbrevEnd = monthAbbreviations[new Date(parseInt(subscriptionsData[0]?.endDate)).getMonth()]

  const startDate =
    new Date(parseInt(subscriptionsData[0]?.startDate)).getDate() +
    " " +
    monthAbbrevStart +
    " " +
    new Date(parseInt(subscriptionsData[0]?.startDate)).getFullYear()

  const endDate =
    new Date(parseInt(subscriptionsData[0]?.endDate)).getDate() +
    " " +
    monthAbbrevEnd +
    " " +
    new Date(parseInt(subscriptionsData[0]?.endDate)).getFullYear()

  return (
    <GridContainer sx={styles.grid}>
      <GridContainer>
        <Typography variant="body2" sx={{ fontSize: "26px", fontWeight: "bold", color: "gray" }}>
          Billing
        </Typography>
        <Divider sx={{ width: "100%", mt: "10px", mb: "30px" }} />
      </GridContainer>
      <GridContainer sx={{ gap: "20px" }}>
        <Paper sx={styles.paper}>
          <GridItem sx={{ p: "20px" }}>
            <Typography variant="body2" sx={{ fontSize: "18px", fontWeight: "bold", mb: "25px" }}>
              Your subscription
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "30px", ml: "10px" }}>
              {subscriptionsData.length !== 0 ? subscriptionsData[0]?.type : "N/A"}
            </Typography>
          </GridItem>
          <GridContainer sx={{ paddingX: "30px" }}>
            <GridItem sx={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="body2" sx={{ fontSize: "16px" }}>
                Start:
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "16px", pl: "5px" }}>
                {subscriptionsData.length !== 0 ? startDate : "N/A"}
              </Typography>
            </GridItem>
            <GridItem sx={{ display: "flex", flexDirection: "row", ml: "auto" }}>
              <Typography variant="body2" sx={{ fontSize: "16px" }}>
                End:
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "16px", pl: "5px" }}>
                {subscriptionsData.length !== 0 ? endDate : "N/A"}
              </Typography>
            </GridItem>
          </GridContainer>
          <Divider sx={{ width: "100%", mt: "15px", mb: "20px" }} />
          <GridContainer sx={{ mt: "25px" }}>
            <Typography variant="body2" sx={{ fontSize: "16px", ml: "30px" }}>
              Looking to get even more from SIOT?
            </Typography>
            <Typography onClick={() => navigate("/subscriptions")} variant="body2" sx={styles.link1}>
              View all options.
            </Typography>
          </GridContainer>
        </Paper>
        <Paper sx={styles.paper1}>
          <GridItem sx={{ p: "20px" }}>
            <Typography variant="body2" sx={{ fontSize: "18px", fontWeight: "bold", mb: "25px" }}>
              Next bill
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "30px", ml: "10px" }}>
              € 0
            </Typography>
          </GridItem>
          <Typography variant="body2" sx={{ paddingX: "30px", fontSize: "16px", color: "#a6a6a6" }}>
            on 12 Dec 2024
          </Typography>
          <Divider sx={{ width: "100%", mt: "15px", mb: "20px" }} />
          <GridItem sx={{ display: "flex", ml: "30px" }}>
            <PriceBreakdownModal />
            <Button onClick={scrollToPosition} sx={styles.button}>
              View transaction history
            </Button>
          </GridItem>
        </Paper>
      </GridContainer>
      <GridItem sx={{ width: "100%" }}>
        <Paper sx={styles.paper2}>
          <GridItem>
            <Typography variant="body2" sx={{ fontSize: "18px", fontWeight: "bold", mb: "35px" }}>
              Billing Information
            </Typography>
          </GridItem>
          <GridContainer sx={{ justifyContent: "space-between", height: "70px" }}>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Payment details
            </Typography>
            {!showBillingMethodInput && imageMap()}
            {showBillingMethodInput ? (
              <GridItem>
                <FormControl sx={{ ml: "auto", minWidth: 140 }} size="small">
                  <InputLabel sx={{ fontSize: "14px", fontWeight: "bold" }}>Billing method</InputLabel>
                  <Select
                    onFocus={() => setShowBillingMethodInput(true)}
                    sx={{ width: "350px", height: "40px" }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={billingMethod}
                    label="Billing method"
                    onChange={handleChangeBillingMethod}
                  >
                    <MenuItem value={"Voucher"}>Voucher</MenuItem>
                    <MenuItem value={"CreditCard"}>Credit Card</MenuItem>
                    <MenuItem value={"PayPal"}>Pay Pal</MenuItem>
                  </Select>
                </FormControl>
                <SendOutlinedIcon sx={styles.sendIcon1} onClick={() => setShowBillingMethodInput(false)} />
              </GridItem>
            ) : (
              <GridItem onClick={() => openSelectField()} sx={{ display: "flex", ...styles.link1 }}>
                <EditOutlinedIcon fontSize="small" />
                <Typography variant="body2" sx={{ fontSize: "15px", pl: "5px" }}>
                  Edit payment details
                </Typography>
              </GridItem>
            )}
          </GridContainer>
          <Divider sx={{ width: "100%", mb: "30px" }} />
          <GridContainer sx={{ justifyContent: "space-between", height: "70px" }}>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Billing address
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", pl: "5px" }}>
              {!showAddressInput && billingAddress}
            </Typography>
            {showAddressInput ? (
              <GridItem>
                <TextField
                  sx={{ width: "350px", height: "40px" }}
                  focused={showAddressInput}
                  id="billingAddress"
                  label="Edit billing address"
                  variant="standard"
                  value={billingAddress}
                  onChange={handleChangeBillingAddress}
                />
                <SendOutlinedIcon sx={styles.sendIcon} onClick={() => setShowAddressInput(false)} />
              </GridItem>
            ) : (
              <GridItem onClick={() => openInputField()} sx={{ display: "flex", ...styles.link1 }}>
                <EditOutlinedIcon fontSize="small" />
                <Typography variant="body2" sx={{ fontSize: "15px", pl: "5px" }}>
                  Edit billing address
                </Typography>
              </GridItem>
            )}
          </GridContainer>
          <Divider sx={{ width: "100%", mb: "30px" }} />
          <GridContainer sx={{ justifyContent: "space-between", height: "70px" }}>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", color: "gray" }}>
              Tax ID
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", pl: "5px", color: "gray" }}>
              Optional
            </Typography>
            <GridItem sx={styles.linkDisabled}>
              <Typography variant="body2" sx={{ fontSize: "15px", pl: "5px" }}>
                Add tax ID
              </Typography>
            </GridItem>
          </GridContainer>
        </Paper>
        {showBillingHistory && (
          <Paper sx={styles.paper3}>
            <PaymentHistory />
          </Paper>
        )}
      </GridItem>
    </GridContainer>
  )
}
export default BillingPage
