import React, { useState } from "react"
import GridContainer from "../../components/basic/grid/GridContainer"
import GridItem from "../../components/basic/grid/GridItem"
import { Alert, AlertTitle, Box, Divider, InputLabel, Paper, Stack, Typography } from "@mui/material"
import visa1 from "../../assets/images/visa2.png"
import paypal from "../../assets/images/paypal.png"
import voucher from "../../assets/images/voucher.png"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { AppTheme } from "../../AppTheme"
import { useMutation, useQuery } from "@tanstack/react-query"
import DependencyContainer from "../../DependencyContainer"
import { VoucherCheckUseType } from "../../repository/VouchersRepository"
import Breadcrumb from "../../components/basic/breadcrumb/Breadcrumb"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Button from "@mui/material/Button"
import ContainedButton from "../../components/basic/buttons/ContainedButton"
import DateRenderer from "../../components/table/DateRenderer"
import { ClipLoader } from "react-spinners"
import { jwtDecode } from "jwt-decode"
import { Link } from "@mui/material"

interface JwtPayload {
  email: string
  given_name: string
  family_name: string
}

const styles = {
  cards: {
    display: "flex",
    justifyContent: "space-around",
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper1: {
    mt: "20px",
    minWidth: "450px",
    flex: 0.5,
    background: "#fafafa",
    display: "flex",
    flexDirection: "column",
    p: "25px",
    height: "600px",
  },
  paper2: {
    mt: "20px",
    minWidth: "450px",
    flex: 0.5,
    background: "#fafafa",
    display: "flex",
    flexDirection: "column",
    p: "25px",
    height: "700px",
  },
  paper3: {
    mt: "20px",
    minWidth: "450px",
    flex: 0.5,
    background: "#fafafa",
    display: "flex",
    flexDirection: "column",
    p: "25px",
    height: "300px",
  },
  paper4: {
    mt: "20px",
    minWidth: "450px",
    flex: 0.5,
    background: "#fafafa",
    display: "flex",
    flexDirection: "column",
    p: "25px",
    height: "200px",
  },
  error: {
    fontSize: "14px",
    mt: "0px",
    color: "#d64242",
    fontWeight: "bold",
  },
  success: {
    fontSize: "18px",
    mt: "0px",
    color: "#039e27",
    fontWeight: "bold",
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "15px",
    borderRadius: "20px",
    color: "white",
    backgroundColor: AppTheme.palette.primary.main,
    "&:hover": {
      color: "#f2f3f5",
      transition: ".4s ease",
      backgroundColor: AppTheme.palette.primary.main,
    },
  },
  button1: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    borderRadius: "20px",
    color: AppTheme.palette.primary.dark,
    border: `1px solid ${AppTheme.palette.primary.dark}`,
    "&:hover": {
      color: AppTheme.palette.primary.main,
      border: `1px solid ${AppTheme.palette.primary.main}`,
    },
  },
  button2: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    borderRadius: "20px",
    color: AppTheme.palette.primary.dark,
    "&:hover": {
      color: AppTheme.palette.primary.main,
    },
  },
  button4: {
    display: "flex",
    mx: "auto",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "15px",
    borderRadius: "20px",
    color: "white",
    width: "250px",
    mt: "-5px",
    backgroundColor: AppTheme.palette.primary.main,
    "&:hover": {
      color: "#f2f3f5",
      transition: ".4s ease",
      backgroundColor: AppTheme.palette.primary.main,
    },
  },
}

const steps = ["Billing information", "Payment details", "Billing Address"]

const PaymentPage = () => {
  const token = localStorage.getItem("token") as string
  const billingAddress = localStorage.getItem("billing-address") as string
  const decodedToken = token !== null ? (jwtDecode(token) as JwtPayload) : null
  const settings: any = JSON.parse(localStorage.getItem("settings") || "")

  const defaultUserData = {
    firstName: decodedToken !== null ? decodedToken?.given_name : "",
    lastName: decodedToken !== null ? decodedToken?.family_name : "",
    company: "",
    email: decodedToken !== null ? decodedToken?.email : "",
    address: "",
    additionalAddress1: "",
    additionalAddress2: "",
    zipCode: "",
    city: "",
    country: "",
    paymentMethod: "Voucher",
    billingAddress: billingAddress !== null ? billingAddress : "",
  }

  const [userData, setUserData] = useState(defaultUserData)
  const [voucherData, setVoucherData] = useState<VoucherCheckUseType>()
  const [voucherCode, setVoucherCode] = useState("")
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [message, setMessage] = useState("")
  const [openLoader, setOpenLoader] = useState(false)

  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setUserData(defaultUserData)
    setError(false)
    setVoucherData(undefined)
    setVoucherCode("")
    setMessage("")
  }

  // const handleReset = () => {
  //   setActiveStep(0)
  // }

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()


  useQuery({
    queryKey: ["userCheck"],
    queryFn: async () => {
      try {
        await dependencyContainer.dependency.userRepositoryApi.insertUser()
        return { message: "ok" }
      } catch (error) {
        console.log(error)
      }
    },
    onSuccess: () => {
     // console.log("Ok")
    },
  })

  const fetchVoucherData = useMutation({
    mutationFn: () => dependencyContainer.dependency.vouchersRepositoryApi.checkVoucher(voucherCode),
    onSuccess: async (data) => {
      setTimeout(() => {
        setOpenLoader(false)
        setVoucherData(data)
        setErrorMessage("")
      }, 1500)
    },
    onError: (error: any) => {
      setTimeout(() => {
        setOpenLoader(false)
        if (error.response?.data?.message === undefined) {
          setErrorMessage(error.message)
        } else {
          setErrorMessage(error.response?.data?.message)
        }
      }, 1500)
    },
    onMutate: () => {
      setOpenLoader(true)
    },
  })

  const voucherUse = useMutation({
    mutationFn: () => dependencyContainer.dependency.vouchersRepositoryApi.useVoucher(voucherCode),
    onSuccess: async (data) => {
      //send successfully subscription to our service
      setTimeout(() => {
        setOpenLoader(false)
        setMessage("Successfully subscribed, you can continue to the management portal!")
        handleNext()
      }, 1500)
    },
    onError: (error: any) => {
      setTimeout(() => {
        setOpenLoader(false)
        if (error.response?.data?.message === undefined) {
          setErrorMessage(error.message)
        } else {
          setErrorMessage(error.response?.data?.message)
        }
      }, 1500)
    },
    onMutate: () => {
      setOpenLoader(true)
    },
  })

  const handleVoucherCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVoucherCode(event.target.value)
    setErrorMessage("")
    setVoucherData(undefined)
    setError(false)
  }

  const handleUserDataChange = (event: any) => {
    const newValue = event.target.value
    const id = event.target.id || event.target.name
    setUserData({
      ...userData,
      [id]: newValue,
    })
    setError(false)
    setErrorMessage("")
  }

  const proceedToNextStep = () => {
    if (userData.firstName === "" || userData.lastName === "" || userData.email === "") {
      setError(true)
    } else {
      handleNext()
    }
  }
  const proceedToNextStep2 = () => {
    if (userData.paymentMethod === "") {
      setError(true)
    } else {
      if (voucherData) handleNext()
    }
  }
  const proceedToNextStep3 = () => {
    voucherUse.mutate()
  }

  return (
    <GridContainer sx={styles.grid}>
      <GridContainer>
        <Breadcrumb link={"subscriptions"} page={"Payment"} />
        <Divider sx={{ width: "100%", mt: "10px" }} />
      </GridContainer>
      <Box sx={{ width: "100%", mt: "50px" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
            } = {}
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{<div style={activeStep === index ? { fontWeight: "bold" } : {}}>{label}</div>}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
            <ContainedButton color="inherit" disabled={activeStep === 0 || activeStep === 3} onClick={handleBack} sx={styles.button2}>
              Back
            </ContainedButton>
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
        </React.Fragment>
      </Box>
      <Divider sx={{ width: "100%", mt: "25px" }} />
      {activeStep === 0 && (
        <GridContainer>
          <Paper sx={styles.paper2}>
            <Typography variant="body2" sx={{ fontSize: "18px", fontWeight: "bold", mb: "15px" }}>
              Billing information
            </Typography>
            <TextField
              size="small"
              error={error && userData.firstName === ""}
              sx={{ flex: 1, mt: "15px" }}
              label="First name"
              variant="outlined"
              id="firstName"
              value={userData.firstName}
              onChange={handleUserDataChange}
              InputLabelProps={{
                sx: { fontSize: "15px" },
              }}
            />
            <TextField
              size="small"
              error={error && userData.lastName === ""}
              sx={{ flex: 1, mt: "15px" }}
              label="Last name"
              variant="outlined"
              id="lastName"
              value={userData.lastName}
              onChange={handleUserDataChange}
              InputLabelProps={{
                sx: { fontSize: "15px" },
              }}
            />
            <TextField
              size="small"
              sx={{ flex: 1, mt: "15px" }}
              label="Company (optional)"
              variant="outlined"
              id="company"
              value={userData.company}
              onChange={handleUserDataChange}
              InputLabelProps={{
                sx: { fontSize: "15px" },
              }}
            />
            <TextField
              size="small"
              error={error && userData.email === ""}
              fullWidth
              sx={{ mt: "15px" }}
              label="E-mail"
              variant="outlined"
              id="email"
              value={userData.email}
              onChange={handleUserDataChange}
              InputLabelProps={{
                sx: { fontSize: "15px" },
              }}
            />
            <TextField
              size="small"
              sx={{ flex: 1, mt: "15px" }}
              label="Address (optional)"
              variant="outlined"
              id="address"
              value={userData.address}
              onChange={handleUserDataChange}
              InputLabelProps={{
                sx: { fontSize: "15px" },
              }}
            />
            <TextField
              size="small"
              sx={{ flex: 1, mt: "15px" }}
              label="Addition to address 1 (optional)"
              variant="outlined"
              id="additionalAddress1"
              value={userData.additionalAddress1}
              onChange={handleUserDataChange}
              InputLabelProps={{
                sx: { fontSize: "15px" },
              }}
            />
            <TextField
              size="small"
              sx={{ flex: 1, mt: "15px" }}
              label="Addition to address 2 (optional)"
              variant="outlined"
              id="additionalAddress2"
              value={userData.additionalAddress2}
              onChange={handleUserDataChange}
              InputLabelProps={{
                sx: { fontSize: "15px" },
              }}
            />
            <GridContainer sx={{ gap: "15px" }}>
              <TextField
                size="small"
                sx={{ flex: 1, mt: "15px" }}
                label="Postal code (optional)"
                variant="outlined"
                id="zipCode"
                value={userData.zipCode}
                onChange={handleUserDataChange}
                InputLabelProps={{
                  sx: { fontSize: "15px" },
                }}
              />
              <TextField
                size="small"
                sx={{ flex: 1, mt: "15px" }}
                label="City (optional)"
                variant="outlined"
                id="city"
                value={userData.city}
                onChange={handleUserDataChange}
                InputLabelProps={{
                  sx: { fontSize: "15px" },
                }}
              />
            </GridContainer>
            <FormControl size="small" sx={{ mt: "15px" }} fullWidth variant="outlined">
              <InputLabel sx={{ fontSize: "15px" }}>Country (optional)</InputLabel>
              <Select name="country" value={userData.country} label="Country (optional)" onChange={handleUserDataChange}>
                <MenuItem value={"Serbia"}>Serbia</MenuItem>
                <MenuItem value={"Switzerland"}>Switzerland</MenuItem>
                <MenuItem value={"Austria"}>Austria</MenuItem>
                <MenuItem value={"Germany"}>Germany</MenuItem>
              </Select>
            </FormControl>

            <GridItem style={{ height: "75px" }}>
              {error && (
                <Alert sx={{ pt: "5px", mt: "15px", backgroundColor: "transparent" }} severity="error">
                  <AlertTitle sx={styles.error}>First name, last name and email are required !</AlertTitle>
                </Alert>
              )}
            </GridItem>
            <Stack spacing={2} direction="row" sx={{ justifyContent: "space-between", mt: "auto" }}>
              <Button sx={styles.button1} onClick={() => setUserData(defaultUserData)}>
                Cancel
              </Button>
              <Button sx={styles.button} onClick={proceedToNextStep}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Stack>
          </Paper>
        </GridContainer>
      )}
      {activeStep === 1 && (
        <GridContainer>
          <Paper sx={styles.paper1}>
            <Typography variant="body2" sx={{ fontSize: "18px", fontWeight: "bold", mb: "15px" }}>
              Payment details
            </Typography>
            <GridContainer sx={styles.cards}>
              <GridItem sx={styles.image}>
                <img
                  src={`${voucher}`}
                  alt="Voucher"
                  style={{ width: "100px", height: "60px", opacity: `${userData.paymentMethod === "Voucher" ? "1" : "0.3"}` }}
                />
              </GridItem>
              <GridItem sx={styles.image}>
                <img
                  src={`${visa1}`}
                  alt="Visa"
                  style={{ width: "100px", height: "60px", opacity: `${userData.paymentMethod === "CreditCard" ? "1" : "0.3"}` }}
                />
              </GridItem>
              <GridItem sx={styles.image}>
                <img
                  src={`${paypal}`}
                  alt="Paypal"
                  style={{ width: "100px", height: "60px", opacity: `${userData.paymentMethod === "PayPal" ? "1" : "0.3"}` }}
                />
              </GridItem>
            </GridContainer>
            <FormControl size="small" error={error && userData.paymentMethod === ""} sx={{ mt: "15px" }} fullWidth variant="outlined">
              <InputLabel sx={{ fontSize: "15px" }}>Payment Method</InputLabel>
              <Select name="paymentMethod" value={userData.paymentMethod} label="Payment Method" onChange={handleUserDataChange}>
                <MenuItem value={"Voucher"}>Voucher</MenuItem>
                <MenuItem value={"CreditCard"}>Credit Card</MenuItem>
                <MenuItem value={"PayPal"}>PayPal</MenuItem>
              </Select>
            </FormControl>
            {userData.paymentMethod === "Voucher" && (
              <>
                <TextField
                  error={errorMessage !== "" && voucherCode === ""}
                  disabled={openLoader}
                  sx={{ mt: "15px", mb: "20px" }}
                  label="Voucher code"
                  variant="outlined"
                  size="small"
                  value={voucherCode}
                  onChange={handleVoucherCodeChange}
                  InputLabelProps={{
                    sx: { fontSize: "15px" },
                  }}
                />
                <ContainedButton
                  disabled={openLoader}
                  onClick={() => {
                    setErrorMessage("")
                    setVoucherData(undefined)
                    fetchVoucherData.mutate()
                  }}
                  sx={styles.button}
                >
                  Check
                </ContainedButton>
                <GridItem style={{ height: "75px" }}>
                  {errorMessage !== "" && (
                    <Alert sx={{ pt: "5px", mt: "15px", backgroundColor: "transparent" }} severity="error">
                      <AlertTitle sx={styles.error}>{errorMessage}</AlertTitle>
                    </Alert>
                  )}
                </GridItem>
                {voucherData && (
                  <GridItem sx={{ backgroundColor: "transparent", color: "#3a7a44" }}>
                    <Typography variant="body2" sx={{ mb: "10px" }}>
                      Service ID: <b>{voucherData.serviceId}</b>
                    </Typography>
                    <Typography variant="body2" sx={{ mb: "10px" }}>
                      Due Date:{" "}
                      {voucherData.dueDate !== null ? (
                        <b>
                          <DateRenderer value={parseInt(voucherData.dueDate)} />
                        </b>
                      ) : (
                        <b>Unlimited</b>
                      )}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: "10px" }}>
                      Description: <b>{voucherData.description}</b>
                    </Typography>
                    <Typography variant="body2" sx={{ mb: "10px" }}>
                      Price:{" "}
                      <b>
                        {voucherData.price?.price} {voucherData.price?.currency}
                      </b>
                    </Typography>
                    <Typography variant="body2">
                      Active: <b>Yes</b>
                    </Typography>
                  </GridItem>
                )}
                {openLoader && (
                  <GridItem sx={{ p: "15px", mt: "auto", mb: "25px", mx: "auto" }}>
                    <ClipLoader size={22} loading={openLoader} />
                  </GridItem>
                )}
              </>
            )}
            <GridItem style={{ height: "75px" }}>
              {error && (
                <Alert sx={{ pt: "5px", mt: "15px", backgroundColor: "transparent" }} severity="error">
                  <AlertTitle sx={styles.error}>Payment method is required !</AlertTitle>
                </Alert>
              )}
            </GridItem>
            <Stack spacing={2} direction="row" sx={{ justifyContent: "space-between", mt: "auto" }}>
              <Button
                sx={styles.button1}
                onClick={() => {
                  setVoucherCode("")
                  setUserData(defaultUserData)
                }}
              >
                Cancel
              </Button>
              <ContainedButton disabled={voucherData === undefined} sx={styles.button} onClick={proceedToNextStep2}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </ContainedButton>
            </Stack>
          </Paper>
        </GridContainer>
      )}
      {activeStep === 2 && (
        <GridContainer>
          <Paper sx={styles.paper3}>
            <Typography variant="body2" sx={{ fontSize: "18px", fontWeight: "bold", mb: "15px" }}>
              Billing address (optional)
            </Typography>
            <TextField
              size="small"
              sx={{ flex: 1, mt: "15px" }}
              label="Billing address (optional)"
              variant="outlined"
              id="billingAddress"
              value={userData.billingAddress}
              onChange={handleUserDataChange}
              InputLabelProps={{
                sx: { fontSize: "15px" },
              }}
            />
            <GridItem style={{ height: "75px" }}>
              {error && (
                <Alert sx={{ pt: "5px", mt: "15px", backgroundColor: "transparent" }} severity="error">
                  <AlertTitle sx={styles.error}>Billing address is required !</AlertTitle>
                </Alert>
              )}
              {errorMessage !== "" && (
                <Alert sx={{ pt: "5px", mt: "15px", backgroundColor: "transparent" }} severity="error">
                  <AlertTitle sx={styles.error}>{errorMessage}</AlertTitle>
                </Alert>
              )}
              {openLoader && (
                <GridItem sx={{ p: "15px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <ClipLoader size={22} loading={openLoader} />
                </GridItem>
              )}
            </GridItem>
            <Stack spacing={2} direction="row" sx={{ justifyContent: "space-between", mt: "auto" }}>
              <Button sx={styles.button1} onClick={() => setUserData(defaultUserData)}>
                Cancel
              </Button>
              <Button sx={styles.button} onClick={proceedToNextStep3}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Stack>
          </Paper>
        </GridContainer>
      )}
      {activeStep === 3 && (
        <Paper sx={styles.paper4}>
          <GridItem style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Alert sx={{ mt: "15px", backgroundColor: "transparent" }} severity="success">
              <AlertTitle sx={styles.success}>{message}</AlertTitle>
            </Alert>
          </GridItem>
          <ContainedButton sx={styles.button4} component={Link} target="blank" href={`${settings.MANAGEMENT_PORTAL_URL}/?token=${token}`} underline="none">
            SIOT Management Portal
          </ContainedButton>
        </Paper>
      )}
    </GridContainer>
  )
}
export default PaymentPage
