import React, { useEffect, useState } from "react"
import GridItem from "../../basic/grid/GridItem"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import NoData from "../../basic/noData/NoData"
import { Usage } from "../../../repository/SubscriptionsRepository"

interface Props {
  rowData: Usage[]
  resolution?: string
  selectedSubdomain: string
}

const UsageGraph = ({ rowData, resolution, selectedSubdomain }: Props) => {

  const [selectedRowData, setSelectedRowData] = useState<any>()

  useEffect(() => {
    rowData?.map((data: any) => {
      return selectedSubdomain === data.subdomain ? setSelectedRowData(data) : null
    })
  }, [rowData, selectedSubdomain])

  useEffect(() => {
    const mapResolution = (resolution: string | undefined) => {
      switch (resolution) {
        case "year":
          return selectedRowData?.year
        case "month":
          return selectedRowData?.month
        case "week":
          return selectedRowData?.week
        case "day":
          return selectedRowData?.day
        default:
          return selectedRowData?.month
      }
    }

    let root = am5.Root.new("chartdiv")

    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        paddingLeft: 50,
      })
    )

    chart.plotContainer.children.push(am5.Label.new(root, { text: "Costs (€)", x: selectedRowData !== undefined ? -70 : -50, y: 190, rotation: -90 }))

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}))
    cursor.lineY.set("visible", false)

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        y: 325,
      })
    )

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "xValue",
        renderer: am5xy.AxisRendererX.new(root, {
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    )

    xAxis.data.setAll(selectedRowData !== undefined ? mapResolution(resolution) : [])

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 13,
      paddingTop: 10,
    })

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        min: 0,
      })
    )

    yAxis.get("renderer").labels.template.setAll({
      fontSize: 13,
    })

    function makeSeries(name: any, fieldName: any, hidden: any) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "xValue",
        })
      )

      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY.formatNumber('#,###.00')} €",
        width: am5.percent(90),
        tooltipY: 0,
      })

      series.data.setAll(selectedRowData !== undefined ? mapResolution(resolution) : [])

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        })
      })

      legend.data.push(series)

      if (hidden) {
        series.hide()
      } else {
        series.appear()
      }
    }

    makeSeries("Total Costs", "total", false)
    makeSeries("Read Operations", "read", false)
    makeSeries("Write Operations", "write", false)
    makeSeries("Storage", "storage", false)

    chart.appear(1000, 100)

    return () => {
      root.dispose()
    }
  }, [rowData, resolution, selectedRowData, selectedSubdomain])

  return (
    <>
      <GridItem style={{ width: "100%", height: "100%" }}>
        <div id="chartdiv" style={{ width: "100%", height: "100%" }}></div>
      </GridItem>
      {(rowData?.length === 0 || rowData?.length === 1) && <NoData />}
    </>
  )
}
export default UsageGraph
