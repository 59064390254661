import React, { Fragment, useState } from "react"
import Box from "@mui/material/Box"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import { Button } from "@mui/material"
import { useLanguage } from "../../../languageContext"
import { AppTheme } from "../../../AppTheme"

const styles = {
  menuItem: {
    color: AppTheme.palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      background: AppTheme.palette.primary.main,
      color: "white",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      width: "60px",
    },
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
    height: "32px",
    ml: "auto",
    mr: { xs: "10px", sm: "25px", md: "25px", lg: "25px", xl: "25px" },
    borderRadius: "100px",
    borderColor: AppTheme.palette.primary.main,
    color: AppTheme.palette.primary.main,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: AppTheme.palette.primary.main,
      transition: ".4s ease",
      color: "white",
    },
  },
}

export default function AccountMenu() {
  const { currentLanguage, changeLanguage } = useLanguage()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Button
          disabled
          variant="outlined"
          onClick={handleClick}
          size="small"
          sx={styles.button}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {currentLanguage === "de" ? "DE" : "EN"}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={styles.menu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => changeLanguage(currentLanguage !== "de" ? "de" : "en")} sx={styles.menuItem}>
          <Typography variant="body2" sx={{ fontSize: "15px" }}>
            {currentLanguage !== "de" ? "DE" : "EN"}
          </Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}
