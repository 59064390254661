import { blue } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"


export const AppTheme = createTheme({
  palette: {
    background: {
      default: "white",
    },
    primary: {
      light: blue[50],
      main: blue[300],
      dark: blue[800],
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#F65656',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          fontWeight: "bold",
          backgroundColor: "#1aae9f",
          padding: "15px 10px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "15px",
          border: "1px solid #6e6e6e",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: "2px 16px",
        },
        message: {
          fontSize: "14px",
        },
      },
    },
  },
})
