import React, { useEffect, useState } from "react"
import GridContainer from "../../components/basic/grid/GridContainer"
import { Divider, Paper, Typography } from "@mui/material"
import { Subscription, Usage } from "../../repository/SubscriptionsRepository"
import { AppTheme } from "../../AppTheme"
import GridItem from "../../components/basic/grid/GridItem"
import UsageGraph from "../../components/advanced/charts/UsageGraph"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"

interface Props {
  subscriptionsData: Subscription[]
  graphData: Usage[]
}

const styles = {
  cards: {
    minWidth: "500px",
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  expandIcon: {
    color: "black",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper2: {
    mt: "20px",
    flex: 1.5,
    height: "450px",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    padding: "20px",
    minWidth: "400px",
  },
  paper21: {
    mt: "20px",
    flex: 1,
    height: "auto",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    padding: "20px",
    minWidth: "480px",
  },
  divider: {
    flex: 1,
    my: "auto",
    minWidth: "200px",
    height: "10px",
    background: AppTheme.palette.primary.light,
  },
}

const OverviewSiotPage = ({ subscriptionsData, graphData }: Props) => {
  const [resolution, setResolution] = useState("year")
  const [selectedSubdomain, setSelectedSubdomain] = useState("")
  
  useEffect(() => {
    if (graphData?.length > 0) setSelectedSubdomain("SUM")
  }, [graphData])

  const handleChangeResolution = (event: SelectChangeEvent) => {
    setResolution(event.target.value)
  }

  const handleChangeSelectedSubdomain = (event: SelectChangeEvent) => {
    setSelectedSubdomain(event.target.value)
  }

  const monthAbbreviations = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

  const monthAbbrevStart =
    subscriptionsData !== undefined ? monthAbbreviations[new Date(parseInt(subscriptionsData[0]?.startDate)).getMonth()] : "n/a"
  const monthAbbrevEnd = subscriptionsData !== undefined ? monthAbbreviations[new Date(parseInt(subscriptionsData[0]?.endDate)).getMonth()] : "n/a"

  const startDate =
    subscriptionsData !== undefined
      ? new Date(parseInt(subscriptionsData[0]?.startDate)).getDate() +
        " " +
        monthAbbrevStart +
        " " +
        new Date(parseInt(subscriptionsData[0]?.startDate)).getFullYear()
      : "n/a"

  const endDate =
    subscriptionsData !== undefined
      ? new Date(parseInt(subscriptionsData[0]?.endDate)).getDate() +
        " " +
        monthAbbrevEnd +
        " " +
        new Date(parseInt(subscriptionsData[0]?.endDate)).getFullYear()
      : "n/a"

  const headingMap = () => {
    switch (resolution) {
      case "year":
        return subscriptionsData[0]?.cost?.length !== 0 ? subscriptionsData[0].cost[0]?.yearCosts.period[0] : ""
      case "month":
        return subscriptionsData[0]?.cost?.length !== 0
          ? subscriptionsData[0].cost[0]?.monthCosts.period[0] +
              " - " +
              subscriptionsData[0].cost[0]?.monthCosts.period[subscriptionsData[0].cost[0]?.monthCosts.period.length - 1]
          : ""
      case "week":
        return "week"
      case "day":
        return subscriptionsData[0]?.cost?.length !== 0
          ? subscriptionsData[0].cost[0]?.dayCosts.period[0] +
              " - " +
              subscriptionsData[0].cost[0]?.dayCosts.period[subscriptionsData[0].cost[0]?.dayCosts.period.length - 1]
          : ""
      default:
        return ""
    }
  }

  return (
    <GridContainer sx={styles.grid}>
      <GridContainer>
        <Typography variant="body2" sx={{ fontSize: "26px", fontWeight: "bold", color: "gray" }}>
          Overview
        </Typography>
        <Divider sx={{ width: "100%", mt: "10px", mb: "30px" }} />
      </GridContainer>
      <GridItem sx={{ flex: 1 }}>
        <Paper sx={styles.paper2}>
          <GridItem sx={{ height: "350px", width: "100%" }}>
            <GridItem sx={{ display: "flex", mt: "10px" }}>
              <GridContainer>
                <Typography variant="body2" sx={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "bold" }}>
                  Usage history
                </Typography>
              </GridContainer>
              <GridContainer>
                <FormControl sx={{ ml: "auto", minWidth: 140 }} size="small">
                  <InputLabel sx={{ fontSize: "14px", fontWeight: "bold" }}>Select Subdomain</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedSubdomain}
                    label="Select Subdomain"
                    onChange={handleChangeSelectedSubdomain}
                  >
                    {graphData?.map((subdomain: Usage) => (
                      <MenuItem key={subdomain?.subdomain} value={subdomain?.subdomain}>
                        {subdomain?.subdomain === "SUM" ? "All subdomains" : subdomain?.subdomain}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ ml: "auto", minWidth: 140 }} size="small">
                  <InputLabel sx={{ fontSize: "14px", fontWeight: "bold" }}>Resolution</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={resolution}
                    label="Resolution"
                    onChange={handleChangeResolution}
                  >
                    <MenuItem value={"year"}>Year</MenuItem>
                    <MenuItem value={"month"}>Month</MenuItem>
                    <MenuItem value={"week"}>Week</MenuItem>
                    <MenuItem value={"day"}>Day</MenuItem>
                  </Select>
                </FormControl>
              </GridContainer>
            </GridItem>
            <UsageGraph rowData={graphData} resolution={resolution} selectedSubdomain={selectedSubdomain} />
          </GridItem>
        </Paper>
      </GridItem>
      <GridContainer sx={{ gap: "20px" }}>
        <Paper sx={styles.paper21}>
          <Typography variant="body2" sx={{ fontSize: "18px", mb: "30px" }}>
            <b>Usage Details:</b> &nbsp; {headingMap()}
            {" - "} {selectedSubdomain === "SUM" ? " All subdomains" : selectedSubdomain}
          </Typography>
          <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Period
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", ml: `${resolution === "year" ? "-30px" : "35px"}` }}>
              Type
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold" , ml: `${resolution === "year" ? "0px" : "25px"}` }}>
              Cost
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Total Costs
            </Typography>
          </GridContainer>
          <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
          {resolution === "year" && (
            <>
              <>
                {selectedSubdomain === "kunbus" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[0]?.yearCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[0]?.yearCosts.cost[0]} {subscriptionsData[0]?.cost[0]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px" }}>
                            {subscriptionsData[0]?.cost[0]?.yearCosts.cost[1]} {subscriptionsData[0]?.cost[0]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px"}}>
                            {subscriptionsData[0]?.cost[0]?.yearCosts.cost[2]} {subscriptionsData[0]?.cost[0]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[0]?.yearCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[0]?.yearCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "hilscher" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[1]?.yearCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.yearCosts.cost[0]} {subscriptionsData[0]?.cost[1]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.yearCosts.cost[1]} {subscriptionsData[0]?.cost[1]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.yearCosts.cost[2]} {subscriptionsData[0]?.cost[1]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[1]?.yearCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[1]?.yearCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "weidmueller" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[2]?.yearCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.yearCosts.cost[0]} {subscriptionsData[0]?.cost[2]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.yearCosts.cost[1]} {subscriptionsData[0]?.cost[2]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.yearCosts.cost[2]} {subscriptionsData[0]?.cost[2]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[2]?.yearCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[2]?.yearCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "SUM" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[3]?.yearCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.yearCosts.cost[0]} {subscriptionsData[0]?.cost[3]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.yearCosts.cost[1]} {subscriptionsData[0]?.cost[3]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.yearCosts.cost[2]} {subscriptionsData[0]?.cost[3]?.yearCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[3]?.yearCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[3]?.yearCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
            </>
          )}
          {resolution === "month" && (
            <>
              <>
                {selectedSubdomain === "kunbus" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[0]?.monthCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[0]?.monthCosts.cost[0]}{" "}
                            {subscriptionsData[0]?.cost[0]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[0]?.monthCosts.cost[1]}{" "}
                            {subscriptionsData[0]?.cost[0]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[0]?.monthCosts.cost[2]}{" "}
                            {subscriptionsData[0]?.cost[0]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[0]?.monthCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[0]?.monthCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "hilscher" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[1]?.monthCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.monthCosts.cost[0]}{" "}
                            {subscriptionsData[0]?.cost[1]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.monthCosts.cost[1]}{" "}
                            {subscriptionsData[0]?.cost[1]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.monthCosts.cost[2]}{" "}
                            {subscriptionsData[0]?.cost[1]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[1]?.monthCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[1]?.monthCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "weidmueller" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[2]?.monthCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.monthCosts.cost[0]}{" "}
                            {subscriptionsData[0]?.cost[2]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.monthCosts.cost[1]}{" "}
                            {subscriptionsData[0]?.cost[2]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.monthCosts.cost[2]}{" "}
                            {subscriptionsData[0]?.cost[2]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[2]?.monthCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[2]?.monthCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "SUM" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[3]?.monthCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.monthCosts.cost[0]}{" "}
                            {subscriptionsData[0]?.cost[3]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.monthCosts.cost[1]}{" "}
                            {subscriptionsData[0]?.cost[3]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.monthCosts.cost[2]}{" "}
                            {subscriptionsData[0]?.cost[3]?.monthCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[3]?.monthCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[3]?.monthCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
            </>
          )}
          {resolution === "week" && (
            <>
              <>
                {selectedSubdomain === "kunbus" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[0]?.weekCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[0]?.weekCosts.cost[0]} {subscriptionsData[0]?.cost[0]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[0]?.weekCosts.cost[1]} {subscriptionsData[0]?.cost[0]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[0]?.weekCosts.cost[2]} {subscriptionsData[0]?.cost[0]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[0]?.weekCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[0]?.weekCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "hilscher" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[1]?.weekCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.weekCosts.cost[0]} {subscriptionsData[0]?.cost[1]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.weekCosts.cost[1]} {subscriptionsData[0]?.cost[1]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.weekCosts.cost[2]} {subscriptionsData[0]?.cost[1]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[1]?.weekCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[1]?.weekCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "weidmueller" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[2]?.weekCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.weekCosts.cost[0]} {subscriptionsData[0]?.cost[2]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.weekCosts.cost[1]} {subscriptionsData[0]?.cost[2]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.weekCosts.cost[2]} {subscriptionsData[0]?.cost[2]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[2]?.weekCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[2]?.weekCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "SUM" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[3]?.weekCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.weekCosts.cost[0]} {subscriptionsData[0]?.cost[3]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.weekCosts.cost[1]} {subscriptionsData[0]?.cost[3]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.weekCosts.cost[2]} {subscriptionsData[0]?.cost[3]?.weekCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[3]?.weekCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[3]?.weekCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
            </>
          )}
          {resolution === "day" && (
            <>
              <>
                {selectedSubdomain === "kunbus" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[0]?.dayCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[0]?.dayCosts.cost[0]} {subscriptionsData[0]?.cost[0]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[0]?.dayCosts.cost[1]} {subscriptionsData[0]?.cost[0]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[0]?.dayCosts.cost[2]} {subscriptionsData[0]?.cost[0]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[0]?.dayCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[0]?.dayCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "hilscher" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[1]?.dayCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.dayCosts.cost[0]} {subscriptionsData[0]?.cost[1]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.dayCosts.cost[1]} {subscriptionsData[0]?.cost[1]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[1]?.dayCosts.cost[2]} {subscriptionsData[0]?.cost[1]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[1]?.dayCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[1]?.dayCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "weidmueller" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[2]?.dayCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.dayCosts.cost[0]} {subscriptionsData[0]?.cost[2]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.dayCosts.cost[1]} {subscriptionsData[0]?.cost[2]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[2]?.dayCosts.cost[2]} {subscriptionsData[0]?.cost[2]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[2]?.dayCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[2]?.dayCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
              <>
                {selectedSubdomain === "SUM" &&
                  subscriptionsData !== undefined &&
                  subscriptionsData[0]?.cost?.length !== 0 &&
                  subscriptionsData[0].cost[3]?.dayCosts.period.map((period: any, index: any) => (
                    <GridItem key={index}>
                      <GridContainer sx={{ justifyContent: "space-between", px: "15px" }}>
                        <Typography variant="body2" sx={{ fontSize: "15px", textAlign: "center" }}>
                          {period}
                        </Typography>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Read
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Write
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            Storage
                          </Typography>
                        </GridItem>
                        <GridItem>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.dayCosts.cost[0]} {subscriptionsData[0]?.cost[3]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.dayCosts.cost[1]} {subscriptionsData[0]?.cost[3]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                            {subscriptionsData[0]?.cost[3]?.dayCosts.cost[2]} {subscriptionsData[0]?.cost[3]?.dayCosts.cost.length === 0 ? "" : "€"}
                          </Typography>
                        </GridItem>
                        <Typography variant="body2" sx={{ fontSize: "15px",  }}>
                          {subscriptionsData[0]?.cost[3]?.dayCosts["totalCosts"][index]}{" "}
                          {subscriptionsData[0]?.cost[3]?.dayCosts.cost.length === 0 ? "" : "€"}
                        </Typography>
                      </GridContainer>
                      <Divider sx={{ width: "100%", mt: "25px", mb: "5px" }} />
                    </GridItem>
                  ))}
              </>
            </>
          )}
        </Paper>
        <Paper sx={styles.paper21}>
          <Typography variant="body2" sx={{ fontSize: "18px", fontWeight: "bold", mb: "30px" }}>
            Your subscription
          </Typography>
          <GridContainer>
            <Typography variant="body2" sx={{ fontSize: "16px", width: "120px", ml: "15px" }}>
              Plan:
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
              {subscriptionsData !== undefined ? subscriptionsData[0]?.type : "N/A"}
            </Typography>
          </GridContainer>
          <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
          <GridContainer>
            <Typography variant="body2" sx={{ fontSize: "16px", width: "120px", ml: "15px" }}>
              ID:
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
              {subscriptionsData !== undefined ? subscriptionsData[0]?.subscriptionId : "N/A"}
            </Typography>
          </GridContainer>
          <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
          <GridContainer>
            <Typography variant="body2" sx={{ fontSize: "16px", width: "120px", ml: "15px" }}>
              Status:
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
              {subscriptionsData !== undefined ? (subscriptionsData[0]?.status ? "Active" : "Inactive") : "N/A"}
            </Typography>
          </GridContainer>
          <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
          <GridContainer>
            <Typography variant="body2" sx={{ fontSize: "16px", width: "120px", ml: "15px" }}>
              Start date:
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
              {subscriptionsData !== undefined ? startDate : "N/A"}
            </Typography>
          </GridContainer>
          <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
          <GridContainer>
            <Typography variant="body2" sx={{ fontSize: "16px", width: "120px", ml: "15px" }}>
              End date:
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
              {subscriptionsData !== undefined ? endDate : "N/A"}
            </Typography>
          </GridContainer>
          <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
          <GridContainer>
            <Typography variant="body2" sx={{ fontSize: "16px", width: "120px", ml: "15px" }}>
              Auto-Renew:
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
              {subscriptionsData !== undefined ? (subscriptionsData[0]?.autoRenewal ? "ON" : "OFF") : "N/A"}
            </Typography>
          </GridContainer>
          <Divider sx={{ width: "100%", mt: "5px", mb: "15px" }} />
          <GridContainer>
            <Typography variant="body2" sx={{ fontSize: "16px", width: "120px", ml: "15px" }}>
              Notifications:
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: "bold", pl: "15px" }}>
              {subscriptionsData !== undefined ? (subscriptionsData[0]?.notifications ? "ON" : "OFF") : "N/A"}
            </Typography>
          </GridContainer>
        </Paper>
      </GridContainer>
    </GridContainer>
  )
}
export default OverviewSiotPage
