import React, { useEffect, useState } from "react"
import OverviewIOToolsPage from "../overviewPages/OverviewIOToolsPage"
import OverviewSiotPage from "../overviewPages/OverviewSiotPage"
import DependencyContainer from "../../DependencyContainer"
import { useQuery } from "@tanstack/react-query"
import { Subscription, Usage } from "../../repository/SubscriptionsRepository"
import OverviewNoDataPage from "../overviewPages/OverviewNoDataPage"
import { useNavigate } from "react-router-dom"

const OverviewPage = () => {
  const [subscriptionsData, setSubscriptionsData] = useState<Subscription[] | undefined>()
  const [graphData, setGraphData] = useState<Usage[]>([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  useQuery({
    queryKey: ["subscriptionsData"],
    queryFn: () => dependencyContainer.dependency.subscriptionsRepositoryApi.subscriptions(),
    onSuccess: (data) => {
      setSubscriptionsData(data)
      setGraphData(data[0]?.usage)
      setLoading(false)
    },
  })

  useEffect(() => {
    if (subscriptionsData?.length === 0) {
      navigate("/subscriptions")
    }
  }, [subscriptionsData, navigate])

  const platformKey = process.env.REACT_APP_PLATFORM_KEY

  if (loading) {
    return <div></div>
  }

  if (platformKey === "siot") {
    return subscriptionsData?.length === 0 ? <OverviewNoDataPage /> : <OverviewSiotPage subscriptionsData={subscriptionsData as Subscription[]} graphData={graphData} />
  } else {
    return subscriptionsData?.length === 0 ? (
      <OverviewNoDataPage />
    ) : (
      <OverviewIOToolsPage subscriptionsData={subscriptionsData} graphData={graphData} />
    )
  }
}
export default OverviewPage
