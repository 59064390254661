import React from "react"
import GridItem from "../grid/GridItem"
import { Typography } from "@mui/material"
import { AppTheme } from "../../../AppTheme"

const styles = {
  noData: {
    fontSize: "1.5rem",
    width: "250px",
    height: "250px",
    mt: "-22%",
    ml: "40%",
    backgroundColor: "transparent",
  },
  typography: {
    height: "100%",
    fontSize: "1.5rem",
    color: AppTheme.palette.primary.dark,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}

const NoData = () => {
  return (
    <GridItem sx={styles.noData}>
      <Typography sx={styles.typography}>No data !</Typography>
    </GridItem>
  )
}

export default NoData
