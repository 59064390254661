import React from "react"
import GridContainer from "../../components/basic/grid/GridContainer"
import { Divider, Paper, Typography } from "@mui/material"
import { AppTheme } from "../../AppTheme"
import GridItem from "../../components/basic/grid/GridItem"
import ContainedButton from "../../components/basic/buttons/ContainedButton"
import { useNavigate } from "react-router-dom"

const styles = {
  cards: {
    minWidth: "500px",
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  expandIcon: {
    color: "black",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: "150px",
    height: "100px",
    background: `linear-gradient(45deg, ${AppTheme.palette.primary.light} ,white, ${AppTheme.palette.primary.light} )`,
    borderRadius: "20px",
    boxShadow: 5,
  },
  paper2: {
    mt: "20px",
    flex: 1.5,
    height: "400px",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    padding: "20px",
    minWidth: "400px",
  },
  paper3: {
    mt: "20px",
    flex: 1,
    height: "auto",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    paddingX: "40px",
    paddingY: "20px",
    minWidth: "400px",
  },
  paper4: {
    mt: "20px",
    flex: 1,
    height: "auto",
    background: "#fafafa",
    boxShadow: 5,
    borderRadius: 4,
    padding: "20px",
    minWidth: "400px",
  },
  divider: {
    flex: 1,
    my: "auto",
    minWidth: "200px",
    height: "10px",
    background: AppTheme.palette.primary.light,
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "16px",
    borderRadius: "20px",
    mt: "25px",
    backgroundColor: AppTheme.palette.primary.main,
    "&:hover": {
      backgroundColor: AppTheme.palette.primary.dark,
      transition: ".4s ease",
    },
  },
}

const OverviewNoDataPage = () => {
  const navigate = useNavigate()

  return (
    <GridContainer sx={styles.grid}>
      <GridContainer>
        <Typography variant="body2" sx={{ fontSize: "26px", fontWeight: "bold", color: "gray" }}>
          Subscription overview
        </Typography>
        <Divider sx={{ width: "100%", mt: "10px", mb: "30px" }} />
      </GridContainer>
      <Paper sx={styles.paper2}>
        <GridContainer sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <GridItem sx={{ width: "100%", textAlign: "center"}}>
            <Typography variant="body2" sx={{ fontSize: "24px" }}>
              You don't have any subscription yet.
            </Typography>
            <Divider sx={{ width: "100%", mt: "20px", mb: "15px" }} />
            <ContainedButton sx={styles.button} onClick={()=> navigate("/subscriptions")} >
              Create a new subscription
            </ContainedButton>
          </GridItem>
        </GridContainer>
      </Paper>
    </GridContainer>
  )
}
export default OverviewNoDataPage
