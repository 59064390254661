import React from "react"
import teal from "@mui/material/colors/teal"
import GridItem from "../basic/grid/GridItem"

const styles = {
  item: {
    width: "75px",
    height: "25px",
    display: "flex",
    fontSize: "12px",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
    borderRadius: "8px",
    fontWeight: "bold",
  }
}  

const InvoiceStatusRenderer = (props: { value: string }) => {
  let cellValue = ""
  let bgColor = ""
  let color = ""

  const status: string = props.value

  if (status === "PAID") {
    cellValue = "Paid"
    bgColor = teal[600]
    color = "white"
  } else if (status === "UNPAID") {
    cellValue = "Unpaid"
    bgColor = "#fdecec"
    color = "#ef4444"
  } else if (status === "CANCELED") {
    cellValue = "Canceled"
    bgColor = "#eaf1f4"
    color = "#347d9f"
  } else if (status === "REFUNDED") {
    cellValue = "Refunded"
    bgColor = "#fce4ac"
    color = "#855d03"
  }

  return (
      <GridItem sx={styles.item} style={{ backgroundColor: bgColor, color: color }}>
        {cellValue}
      </GridItem>
  )
}

export default InvoiceStatusRenderer
