import axios from "axios"
import UserRepository from "./UserRepository"

export default class APIUserRepository implements UserRepository {
  async insertUser(): Promise<void> {
    const settings = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")
    const body = {}
    try {
      await axios.post(`${settings.IOTOOLS_BUSINESS_PORTAL_BACKEND_URL}/v1/insert_user`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
}
