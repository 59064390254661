import React, { useEffect, useState } from "react"
import GridItem from "../../basic/grid/GridItem"
import { Typography } from "@mui/material"
import Search from "../../basic/search/Search"
import DependencyContainer from "../../../DependencyContainer"
import { useQuery } from "@tanstack/react-query"
import { Invoice } from "../../../repository/InvoicesRepository"
import InvoicesGrid from "../grids/InvoicesGrid"

const styles = {
  dashboard: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    py: "15px",
    alignItems: "center",
  },
}

const PaymentHistory = () => {
  const [filteredData, setFilteredData] = useState([])
  const [searchWord, setSearchWord] = useState("")
  const [rowData, setRowData] = useState<Invoice[]>([])

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  useQuery({
    queryKey: ["invoicesData"],
    queryFn: () => dependencyContainer.dependency.invoicesRepositoryApi.invoices(),
    onSuccess: (data) => {
      setRowData(data)
    },
  })

  const handleSearch = (event: any) => {
    const searchWord = event.target.value
    setSearchWord(searchWord)
    const filteredList = rowData.filter((item) => {
      return item.invoiceId.toLowerCase().includes(searchWord.toLowerCase())
    })
    setFilteredData(filteredList as any)
  }

  const resetSearchWord = () => {
    setSearchWord("")
    setFilteredData(rowData as any)
  }

  useEffect(() => {
    setFilteredData(rowData as any)
  }, [rowData])

  return (
    <GridItem sx={{ flex: 1 }}>
      <GridItem>
        <GridItem sx={styles.flex}>
          <Typography variant="body2" sx={styles.dashboard}>
            Transaction history
          </Typography>
          <Search handleSearch={handleSearch} searchWord={searchWord} resetSearchWord={resetSearchWord} />
        </GridItem>
      </GridItem>
      <InvoicesGrid rowData={filteredData} />
    </GridItem>
  )
}
export default PaymentHistory
