import React, { Fragment, useState } from "react"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import GridItem from "../grid/GridItem"
// import Badge from "@mui/material/Badge"
// import { styled } from "@mui/material/styles"
//import { useLanguage } from "../../../languageContext"
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined"
import { AppTheme } from "../../../AppTheme"

const styles = {
  menuItem: {
    "&:hover": {
      background: AppTheme.palette.primary.light,
    },
  },
  menu: {
    "& .MuiPaper-root": {
      width: "auto",
      height: "auto",
    },
    ml: "18px",
  },
  icon: {
    width: "42px",
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#666666",
  },
  iconHolderActive1: {
    //   borderRadius: "100%",
    //   width: "42px",
    //   height: "42px",
    //   display: "flex",
    //   mr: "22px",
    //   ml: "auto",
    //   "&:hover": {
    //     background: AppTheme.palette.primary.light,
    //   },
    //   cursor: "pointer",
    // },
    borderRadius: "100%",
    width: "42px",
    height: "42px",
    display: "flex",
    mr: "22px",
    ml: "auto",
    color: "#9d9d9e",
    cursor: "not-allowed",
    "&:hover": {
      background: "transparent",
    },
  },
}

export default function NotificationsMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  //const { t } = useLanguage()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  // const StyledBadgeNotification = styled(Badge)(({ theme }) => ({
  //   "& .MuiBadge-badge": {
  //     color: "black",
  //     fontSize: "10px",
  //     fontWeight: "bold",
  //     background: "#ffd648",
  //     marginTop: "6px",
  //     marginRight: "6px",
  //     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  //   },
  // }))

  return (
    <Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          sx={styles.iconHolderActive1}
          style={{ marginLeft: "5px" }}
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {/* <StyledBadgeNotification badgeContent={3} max={99}> */}
            <GridItem sx={styles.icon}>
              <NotificationsOutlinedIcon />
            </GridItem>
          {/* </StyledBadgeNotification> */}
        </IconButton>
      </Box>
      {/* <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={styles.menu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose} sx={styles.menuItem}>
          <NoAccessPage />
        </MenuItem>
      </Menu> */}
    </Fragment>
  )
}
