import React from "react"
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom"
import Navigation from "../components/advanced/sideBar/SideBar"
import OverviewPage from "../pages/mainPages/OverviewPage"
import ScrollToTop from "../ScrollToTop"
import ImpressumPage from "../pages/otherPages/ImpressumPage"
import PrivacyPolicyPage from "../pages/otherPages/PrivacyPolicyPage"
import AppLoader from "../appLoader/AppLoader"
import AppKeycloak from "../Keycloak"
import BillingPage from "../pages/mainPages/BillingPage"
import SubscriptionsPage from "../pages/mainPages/SubscriptionsPage"
import ManagementPage from "../pages/mainPages/ManagementPage"
import PaymentPage from "../pages/otherPages/PaymentPage"

const token = localStorage.getItem("token")

const protectedRoutes = [
  {
    index: true,
    path: "overview",
    element: <OverviewPage />,
  },
  {
    path: "billing",
    element: <BillingPage />,
  },
  {
    path: "subscriptions",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <SubscriptionsPage />,
      },
      {
        path: "payment",
        element: <PaymentPage />,
      },
    ],
  },
  {
    path: "management",
    element: <ManagementPage />,
  },
  {
    path: "/impressum",
    element: <ImpressumPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "*",
    element: <Navigate replace to={"/overview"} />,
  },
  {
    path: "/",
    element: <Navigate to="/overview" replace={true} />,
  },
]

export default function AppRouter() {
  const router = React.useMemo(() => {
    const adminApp = {
      element: <Navigation />,
      children: [...protectedRoutes],
    }

    return createBrowserRouter([
      {
        path: "/",
        element: !token ? <AppLoader keycloakStore={AppKeycloak.getAppKeycloak().store()} /> : <Navigate to={"/overview"} replace={true} />,
      },
      {
        path: "/",
        element: (
          <ScrollToTop>
            <Outlet />
          </ScrollToTop>
        ),
        children: [adminApp],
      },
    ])
  }, [])

  return <RouterProvider router={router} />
}
