import React, { useRef, useMemo } from "react"
import { AgGridReact } from "ag-grid-react"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import "../../../customCss/table-styles.css"
import CenterHeader from "../../basic/grid/CenterHeader"
import DateRenderer from "../../table/DateRenderer"
import InvoiceStatusRenderer from "../../table/InvoiceStatusRenderer"
import { Invoice } from "../../../repository/InvoicesRepository"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import GridItem from "../../basic/grid/GridItem"
import { Typography } from "@mui/material"

LicenseManager.setLicenseKey(
  "CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3"
)

interface Props {
  rowData: Invoice[]
}

const InvoicesGrid = ({ rowData }: Props) => {
  const gridRef = useRef<AgGridReact>(null)

  const columnDefs = [
    {
      field: "invoiceId",
      headerName: "Invoice Number",
      autoHeight: true,
      filter: true,
      cellStyle: function () {
        return { fontWeight: "bold", display: "flex", alignItems: "center" }
      },
      minWidth: 200,
    },
    {
      field: "invoiceDate",
      headerName: "Date",
      filter: true,
      cellStyle: function () {
        return { fontWeight: "bold", display: "flex", alignItems: "center" }
      },
      cellRenderer: (row: any) => <DateRenderer value={parseInt(row.data.invoiceDate)} />,
      minWidth: 170,
    },
    {
      field: "transaction",
      headerName: "Transaction",
      cellStyle: function () {
        return { fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "start" }
      },
      cellRenderer: (row: any) => "Standard Monthly",
      minWidth: 170,
    },
    {
      field: "total",
      headerName: "Amount",
      headerClass: "ag-left-aligned-header",
      cellStyle: function () {
        return { fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "start" }
      },
      valueFormatter: (params: any) => {
        return `$ ${params.value}`
      },
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",
      headerComponent: CenterHeader,
      cellRenderer: (row: any) => <InvoiceStatusRenderer value={row.data.status} />,
      cellStyle: function () {
        return { display: "flex", alignItems: "center", justifyContent: "center" }
      },
      minWidth: 100,
    },
    {
      field: "viewInvoice",
      headerName: "View Invoice",
      headerComponent: CenterHeader,
      cellRenderer: (row: any) => {
        return (
          <GridItem sx={{ display: "flex", cursor: "pointer" }}>
            <a
              style={{ display: "flex", textDecoration: "none", color: "black" }}
              href={`data:text/csv;charset=utf-8,${encodeURIComponent(
                row.data.invoiceId + "," + row.data.invoiceDate + "," + row.data.total + "," + row.data.status + "\n"
              )}`}
              download={`export_data.csv`}
            >
              <FileDownloadOutlinedIcon sx={{ mt: "-5px", mr: "5px" }} />
              <Typography variant="body2">Invoice</Typography>
            </a>
          </GridItem>
        )
      },
      cellStyle: function () {
        return { display: "flex", alignItems: "center", justifyContent: "center" }
      },
      minWidth: 100,
    },
  ]

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      wrapText: false,
      suppressMovable: true,
      flex: 1,
      suppressMenu: true,
    }),
    []
  )

  return (
    <div className="ag-theme-alpine invoicesGrid" style={{ width: "auto", height: "500px" }}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        animateRows={true}
        rowSelection="multiple"
        groupDisplayType="groupRows"
        groupDefaultExpanded={1}
        defaultColDef={defaultColDef}
        suppressContextMenu={true}
        //rowStyle={rowStyle}
        pagination={true}
        paginationAutoPageSize={true}
      />
    </div>
  )
}

export default InvoicesGrid
