import React, { Fragment, forwardRef, useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import ContainedButton from "../../basic/buttons/ContainedButton"
import { Button, Divider, Typography } from "@mui/material"
import GridItem from "../../basic/grid/GridItem"
import { AppTheme } from "../../../AppTheme"
import GridContainer from "../../basic/grid/GridContainer"

const styles = {
  buttonCancel: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "15px",
    borderRadius: "20px",
    color: "white",
    backgroundColor: AppTheme.palette.primary.main,
    "&:hover": {
      color: "#f2f3f5",
      transition: ".4s ease",
      backgroundColor: AppTheme.palette.primary.main,
    },
    flex: 1,
  },
  mainButton: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    mr: "25px",
    borderRadius: "20px",
    color: AppTheme.palette.primary.dark,
    border: `1px solid ${AppTheme.palette.primary.dark}`,
    "&:hover": {
      color: AppTheme.palette.primary.main,
      border: `1px solid ${AppTheme.palette.primary.main}`,
    },
  },
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PriceBreakdownModal = () => {
  const [openPriceBreakdownDialog, setOpenPriceBreakdownDialog] = useState(false)

  const handleClose = () => {
    setOpenPriceBreakdownDialog(false)
  }

  return (
    <Fragment>
      <Button onClick={() => setOpenPriceBreakdownDialog(true)} sx={styles.mainButton}>
        View price breakdown
      </Button>
      <Dialog
        sx={{ "& .MuiPaper-root": { width: "420px" } }}
        open={openPriceBreakdownDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          <GridItem sx={{ fontWeight: "bold", mt: "20px" }}>View price breakdown</GridItem>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <GridContainer sx={{ justifyContent: "space-between" }}>
            <Typography variant="body2" sx={{ mt: "10px" }}>
              Premium monthly subscription <br /> 1 license x 0 €
            </Typography>
            <Typography variant="body2" sx={{ mt: "10px" }}>
              0 €
            </Typography>
          </GridContainer>
          <GridContainer sx={{ justifyContent: "space-between" }}>
            <Typography variant="body2" sx={{ mt: "10px" }}>
              Sales tax:
            </Typography>
            <Typography variant="body2" sx={{ mt: "10px" }}>
              0 €
            </Typography>
          </GridContainer>
          <GridContainer sx={{ justifyContent: "space-between" }}>
            <Typography variant="body2" sx={{ mt: "10px", fontWeight: "bold" }}>
              Total:
            </Typography>
            <Typography variant="body2" sx={{ mt: "10px", fontWeight: "bold" }}>
              0 €
            </Typography>
          </GridContainer>
        </DialogContent>
        <DialogContent>
          <DialogActions>
            <ContainedButton onClick={handleClose} sx={styles.buttonCancel}>
              Close
            </ContainedButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default PriceBreakdownModal
